<template>

<b-row v-if="loading" style="margin-bottom:15px"> 

  <b-col  cols="10" sm="10" md="10" xl="10" lg="10">

<h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
      <feather-icon
                icon="ListIcon"
                size="21"
                class="color_icon"
                /> Listado de retiros
        </h4>

</b-col>


<b-col  cols="2" sm="2" md="2" xl="2" lg="2">
<b-skeleton width="100%"  height="20px"></b-skeleton>

</b-col>



<b-col  cols="4" sm="4" md="4" xl="4" lg="4">


</b-col>

<b-col  cols="4" sm="4" md="4" xl="4" lg="4">

<b-skeleton width="100%"  height="30px"></b-skeleton>

</b-col>

<b-col  cols="4" sm="4" md="4" xl="4" lg="4">


</b-col>

<b-col  cols="3" sm="3" md="3" xl="3" lg="3">


    </b-col>

    <b-col  cols="6" sm="6" md="6" xl="6" lg="6">

    <b-skeleton width="100%"  height="30px"></b-skeleton>

    </b-col>

    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">


    </b-col>

<b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="margin-bottom:10px">




                  <b-skeleton-table
              :rows="2"
              :columns="columnas"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>

</b-col>

<b-col  cols="3" sm="3" md="3" xl="3" lg="3">


</b-col>

<b-col  cols="6" sm="6" md="6" xl="6" lg="6">

<b-skeleton width="100%"  height="30px"></b-skeleton>

</b-col>

<b-col  cols="3" sm="3" md="3" xl="3" lg="3">


</b-col>

<b-col  cols="3" sm="3" md="3" xl="3" lg="3">


</b-col>

<b-col  cols="2" sm="2" md="2" xl="2" lg="2">
<b-skeleton width="100%"  height="30px"></b-skeleton>


</b-col>

<b-col  cols="2" sm="2" md="2" xl="2" lg="2" align="center">
<b-skeleton width="30px" type="avatar"   height="30px"></b-skeleton>


</b-col>

<b-col  cols="2" sm="2" md="2" xl="2" lg="2">
<b-skeleton width="100%"  height="30px"></b-skeleton>


</b-col>


<b-col  cols="3" sm="3" md="3" xl="3" lg="3">


</b-col>



</b-row>

<b-row v-else-if="code200" style="  overflow: auto;">

  <b-modal
      ref="modalDatos"
      centered
      hide-footer
      title="Detalles del retiro de dinero"
      size="lg"
    >
      <div class="d-block text-center">
   

        <b-row>

          <b-col cols="12" sm="12" md="12" lg="4" xl="4">

            <b-card>
                  <h4 class="font-weight-bolder">
                    Datos del usuario
                    <hr style="margin:5px">
                  </h4>


              

                  <p class="p_text"><span class="textLabel">Usuario: </span>{{displayName}}</p>



             
                  <p class="p_text"><span class="textLabel">DNI: </span>{{dni}}</p>



            
                  <p class="p_text"><span class="textLabel">Contacto: </span>{{contact}}</p>




                </b-card>

            </b-col>

            <b-col cols="12" sm="12" md="12" lg="4" xl="4">

              <b-card>
             
                 
             <h4 class="font-weight-bolder">
             Datos bancarios
             <hr style="margin:5px">
           </h4>


    
           <p class="p_text"><span class="textLabel">Banco: </span>{{bank}}</p>


    
           <p class="p_text"><span class="textLabel">Nro. cuenta: </span>{{numberAccount}}</p>


           <p class="p_text"><span class="textLabel">Tipo: </span>{{type}}</p>


         </b-card>

            </b-col>

            <b-col cols="12" sm="12" md="12" lg="4" xl="4">

              
              <b-card>
                    
                    <h4 class="font-weight-bolder">
                    Datos de transacción
                    <hr style="margin:5px">
                  </h4>


                

                    <p class="p_text"><span class="textLabel">Fecha: </span>{{date}}</p>


                

                      <p class="p_text"><span class="textLabel">Referencia: </span>{{ref}}</p>


                   

                    <p class="p_text"><span class="textLabel">Monto: </span>$ {{mount}}</p>


                  

                    <p class="p_text"><span class="textLabel">Monto a transferir: </span>$ {{mounTransfer}}</p>
                </b-card>

            </b-col>




              <b-col cols="12" sm="12" md="12" lg="12" xl="12">

         

            

                <listado-movimientos  :user-id="userId" :user-id-user="userIdUser" :token-auth="tokenAuth" :id-admin="idAdmin" />


                </b-col>

            
           

        </b-row>
   
       

      </div>

    </b-modal>

    <b-col  cols="10" sm="10" md="10" xl="10" lg="10">

<h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
      <feather-icon
                icon="ListIcon"
                size="21"
                class="color_icon"
                /> Listado de retiros
        </h4>

</b-col>

<b-col  cols="2" sm="2" md="2" xl="2" lg="2" style="    text-align: right;">


<p style="cursor:pointer;    margin: 0;" @click="refresh()" > 
                                                            <feather-icon
                                                            icon="RefreshCcwIcon"
                                                            size="16"
                                                            style="margin-right:5px"
                                                            /> <span class="d-none d-sm-none d-md-inline-block d-lg-inline-block d-xl-inline-block"> Recargar </span> </p>



</b-col>


<b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="  overflow: auto; padding: 0px; margin-top:15px">
<table class="table tablesorter"  id="datatable" >
                  <thead class="thead-light">
                    <tr>
                      <th data-priority="1">Fecha</th>
                      <th>Usuario</th>
                      <th>DNI</th>
                      <th>Contacto</th>
                    
                      <th>Monto real</th>
                      <th>Monto a transferirse</th>
                      
                      
                      <th data-priority="2">Acción</th>
                    
                    </tr>
                  </thead>
                  <tbody>


                    <tr v-for="transaccion in transactions" :key="transaccion.idTransaction">

                      <td> {{ transaccion.dateFormated }}</td>
                      <td> {{ transaccion.displayName }}</td>
                      <td> {{ transaccion.dni }}</td>

                      <td>

                                                          
                            <b-input-group >

                            <b-form-input
                            v-model="transaccion.contact"
                            
                            
                                readonly>
                                
                                </b-form-input>

                            <b-input-group-append>
                                <b-button
                                @click="onCopy(transaccion.contact)"
                                variant="outline-primary"
                                >

                                <feather-icon
                            icon="CopyIcon"
                            size="15"

                            /> 
                                </b-button>
                            </b-input-group-append>
                            </b-input-group>


                            </td>

 
            

                        <td> $ {{ transaccion.mount }}</td>

                         <td> $ {{ transaccion.mount_transferir }}</td>

                         <td>

                          <b-dropdown  dropleft variant="light" size="sm"  no-caret>
                                              <template  #button-content>

                                              <feather-icon
                                                icon="MoreVerticalIcon"
                                                size="25"
                                                class="MoreVerticalIcon"
                                                />
                                                


                                              </template>
                                              <b-dropdown-item @click="aprobarRechazar(transaccion.idTransaction,transaccion.userId, 'Aprobar', transaccion.displayName,transaccion.bank, transaccion.mount )">
                                                <feather-icon
                                                icon="CheckCircleIcon"
                                                size="15"
                                                style="margin-right: 10px;"
                                                />

                                                Aprobar retiro
                                              </b-dropdown-item>
                                              <b-dropdown-item @click="aprobarRechazar(transaccion.idTransaction,transaccion.userId, 'Rechazar', transaccion.displayName,transaccion.bank, transaccion.mount)">
                                                <feather-icon
                                                icon="AlertOctagonIcon"
                                                size="15"
                                                style="margin-right: 10px;"
                                                />

                                                Rechazar retiro
                                              </b-dropdown-item>
                                              <b-dropdown-item @click="verDatos(transaccion.displayName, transaccion.dni, transaccion.contact, transaccion.bank, transaccion.numberAccount, transaccion.type, transaccion.dateFormated, transaccion.mount, transaccion.mount_transferir, transaccion.ref, transaccion.userId)">

                                                 
                                                
                                                <feather-icon
                                                icon="EyeIcon"
                                                size="15"
                                                style="margin-right: 10px;"
                                                />
                                                Ver datos</b-dropdown-item>
                                            </b-dropdown>
                         </td>

              

                    </tr>
                  </tbody>
                </table>

              </b-col>


  </b-row>

  <b-row v-else-if="notAllow">

    <b-col  cols="12" sm="12" md="12" xl="12" lg="12" align="center">

      <img style="width:100px;" src="/img/caution.svg" />

      <p style="margin-top:10px; font-size:14px; ">Tu rol no te permite utilizar la siguiente funcionalidad.</p>


      </b-col>

  </b-row>

<b-row v-else-if="empty">

  <b-col  cols="12" sm="12" md="12" xl="12" lg="12">

<h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
      <feather-icon
                icon="ListIcon"
                size="21"
                class="color_icon"
                /> Listado de retiros
        </h4>

</b-col>               

  <b-col  align="center" cols="12" sm="12" md="12" xl="12" lg="12">
          <svg class="icons_colors" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g data-name="17. Withdraw" id="_17._Withdraw"><path d="M31 0H1A1 1 0 0 0 0 1V13a1 1 0 0 0 1 1H6V31a1 1 0 0 0 1 1H25a1 1 0 0 0 1-1V14h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zM2 2H30V4H2zM24 30H8V10H24zm6-18H26V10h1a1 1 0 0 0 0-2H5a1 1 0 0 0 0 2H6v2H2V6H30z"/><path d="M29 23a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V24a1 1 0 0 0-1-1z"/><path d="M3 16a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V17a1 1 0 0 0-1-1z"/><path d="M21 16h-.18A3 3 0 0 0 15 17a1 1 0 0 1-2 0V15a1 1 0 0 0-2 0v1a1 1 0 0 0 0 2h.18A3 3 0 0 0 17 17a1 1 0 0 1 2 0v2a1 1 0 0 0 2 0V18a1 1 0 0 0 0-2z"/><path d="M16 23a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0V24a1 1 0 0 0-1-1z"/></g></svg>                    
                                                  

                          <p class="errorTexto">
                        No se encontraron transacciones
                      </p>

              </b-col>

  <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="    text-align: center;">

                            <b-button   variant="primary"  @click="refresh()" > 
                                          <feather-icon
                                          icon="RefreshCcwIcon"
                                          size="16"
                                          
                                          style="margin-right:5px; color:#fff"
                                          /> Recargar transacciones </b-button>


  </b-col>
  </b-row>
    
</template>
    
    <script>
    import {
      BButton, BRow, BCol,BDropdown,BDropdownItem,BSkeleton, BCard,BSkeletonTable, BAvatar,BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup, 
    } from 'bootstrap-vue'
    import "jquery/dist/jquery.min.js";
    
    import $ from "jquery";
    import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css'
    import 'datatables.net-responsive-dt/js/responsive.dataTables.min.js'
    import ListadoMovimientos from './ListadoMovimientos.vue'
    export default {
      components: {
        BButton,
        BSkeleton,
        BInputGroup,
        ListadoMovimientos,
        BFormInput,
        BInputGroupPrepend,
        BInputGroupAppend,
        BFormGroup,
        BSkeletonTable,
        BRow,
        BCol,
        BCard,
        BDropdown,BDropdownItem,
        BAvatar
      },
      props: ['userId','tokenAuth', 'idAdmin'],
      data() {
    
        const mq = window.matchMedia( "(min-width: 990px)" );
    let columnas=6;

    if (!mq.matches) {

      columnas =3;
      }

    
        return {
          code200: false,
          loading: true,
           empty:false,
           notAllow:false,
           isDisabled:false,
           columnas:columnas,
           transactions:[],
           displayName:"",
           dni:"",
           userIdUser:"",
           contact:"",
           bank:"",
           numberAccount:"",
           type:"",
           date:"",
           mount:"",
           mounTransfer:"",
           ref:"",
    
    
        }
    
    
      },
      computed: {
    
      },
      watch: {
    
      },
      mounted() {


        this.loadTable();


    
    
    
    
      },
      methods: {
        
        

        refresh() {
          this.$eventBus.$emit('reiniciarCountRetirosAdmin')
          this.$eventBus.$emit('reiniciarRetirosAdmin')
        },aprobarRechazar(idTransaction, userId, tipo, displayName, bank, mount){

          let title="";
          let textButton="";
          let html="";
          html='<div style="margin-top:10px" class="table-responsive"><table class="table table-bordered" style="text-align: center;"><tbody><tr><td class="background_table">Usuario:</td><td>'+displayName+'</td></tr><tr><td class="background_table">Banco:</td><td>'+bank+'</td></tr><tr> <td class="background_table">Monto:</td><td>$'+mount+' USD</td></tr></tbody></table>'
                

          if(tipo === "Aprobar"){

            title='¿Estas seguro que deseas aprobar esta solicitud?';
             textButton="Aprobar";
            
          }else{

            title='¿Estas seguro que deseas rechazar esta solicitud?';
             textButton="Rechazar";

          }

            this.$swal({
            title: title,
            html: html,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: textButton,
            cancelButtonText: 'Cancelar',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-secondary ml-1',
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              const data_json = { userId: this.userId, userIdUser:userId,idAdmin:this.idAdmin,  tipo:tipo, idTransaction:idTransaction  }
              const data_string = JSON.stringify(data_json)

           

              const dataEncripted = this.$encryptAdmin.encrypt(data_string)

              return this.$https.post('/administracion/withdrawcheck/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => response.data).catch(error => {
                this.$swal.showValidationMessage(
                  error,
                )
              })
            },
            allowOutsideClick: () => !this.$swal.isLoading(),
          }).then(result => {

            this.$swal.close()

            if(result.isConfirmed){

   



              if (result.value.code == 200) {



                this.refresh();
                   
                        this.$toast.success(result.value.message, {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })


        
                      } else {
                        this.$toast.error(result.value.message, {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })

                        if (result.data.code == 401) {
                          
                          localStorage.removeItem('userData')



                          
                          

              

                          
                          

                          // Redirect to login page
                          this.$router.push({ name: 'auth-login' })
                        }else{
                              if (result.data.code == 402) {
                                this.refresh();

                              }
                            }
                      }

            }
            



      
          })

       


        },verDatos(displayName, dni, contact, bank, numberAccount, type, dateFormated, mount, mount_transferir, ref, userIdUser){

                  this.displayName= displayName;
                  this.dni= dni;
                  this.contact= contact;


                  this.bank= bank
                  this.ref= ref
                  this.bank= bank
                  this.numberAccount= numberAccount;
                  this.userIdUser= userIdUser;
                  this.type= type;
                  this.date= dateFormated;
                  this.mount= mount;
                  this.mounTransfer= Number(mount_transferir).toFixed(2);
                  this.$refs.modalDatos.show();
              
                 


         
        },
        onCopy(copy) {
          navigator.clipboard.writeText(copy);
          this.$toast.success('Texto copiado!', {
            position: 'top-right',
            timeout: 1500,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        },
        onError() {
          this.$toast.error('Falló en copiar el texto', {
            position: 'top-right',
            timeout: 1500,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
        },loadTable(){
          const data_json = { userId: this.userId, idAdmin: this.idAdmin }
          const data_string = JSON.stringify(data_json)

    
          const dataEncripted = this.$encryptAdmin.encrypt(data_string);

            this.$https.post('/administracion/listWithDraw/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {

              this.loading=false;
        

                
              if (response.data.code == 200) {
                this.transactions= response.data["transactions"];
            
                            
                    setTimeout(() => {
                      $("#datatable").DataTable({
                        responsive: true,
                        "ordering": false,
                        columnDefs: [
                                                { responsivePriority: 1, targets: 0 },
                                                { responsivePriority: 2, targets: -1 }
                                            ],
                        lengthMenu: [
                          [6,15, 25, 35, 50, -1],
                          [6,15,25, 35, 50, "All"],
                        ],
                        pageLength: 6, 
                           "language": {
                                              "search": "Buscar:",
                                              "sInfo":      "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
	                                            "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                              "searchPlaceholder": "Ingrese algún dato",
                                              "zeroRecords": "Lo sentimos, no se encontraron resultados",
                                              "processing": "Buscando. Espere por favor...",
                                              "sLengthMenu":     "Mostrar _MENU_ registros",
                                              "oPaginate": {
                                                      "sFirst":    "Primero",
                                                      "sLast":     "Último",
                                                      "sNext":     "Siguiente",
                                                      "sPrevious": "Anterior"
                                                  },

                                              }
                      });
                    });

                        this.code200=true;

              
                


                
              } else {
                
                    


                if (response.data.code == 401) {

                    this.$toast.error(response.data.message, {
                      position: 'top-right',
                      timeout: 3010,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.4,
                      showCloseButtonOnHover: false,
                      hideProgressBar: true,
                      closeButton: 'button',
                      icon: true,
                      rtl: false,
                    })

                    
                    localStorage.removeItem('userData')



                    




                    
                    

                    // Redirect to login page
                    this.$router.push({ name: 'auth-login' })
                    } else {

                      if (response.data.code == 403) {

                        this.$router.push({ name: 'dashboard-analytics' })

                        }else{

                          if(response.data.code === 501){

                            this.notAllow=true;

                          }else{

                            if (response.data.code == 404) {



                              this.empty=true;

                              }else{

                                this.$toast.error(response.data.message, {
                                  position: 'top-right',
                                  timeout: 3010,
                                  closeOnClick: true,
                                  pauseOnFocusLoss: false,
                                  pauseOnHover: true,
                                  draggable: true,
                                  draggablePercent: 0.4,
                                  showCloseButtonOnHover: false,
                                  hideProgressBar: true,
                                  closeButton: 'button',
                                  icon: true,
                                  rtl: false,
                                })

                                this.code200 = false

                              }

                          }
                         

                        }

              



                    } 
                  
                
              }
            }).catch(error => {
                this.loadTable();
            })
        
        }
    
    
      },
    }
    </script>
    
    <style lang="scss">
    
    
    </style>