<template>

    <div>
        <b-modal
      ref="modalTutorial"
      centered
      size="sm"
      hide-footer
      :title="title"
    >
      <div class="d-block text-center">


            <b-form
                autocomplete="off"
                @submit.prevent="onSubmit"
                
              >

              <b-row>

                <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:10px">

                    <b-alert
                    variant="secondary"
                    show
                    style="margin-top:0px;"
                    >
                    <div class="alert-body">
                            

                        <p style="text-align: center;font-size:12px;margin:0px"><b>Nota:</b> El width del video debe ser 100% y el height de 200</p>

                        <p style="text-align: center;font-size:12px;margin:0px"><b>Ejemplo: </b> < iframe width="100%" height="200" /></p>

                    </div>
                    </b-alert>

                    </b-col>

             <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

       
                      <p
                class="textLabel"
                style="text-align: left;margin-top: 0px;margin-bottom: 5px;"
              >
                Título del video (*):  </p>

            
            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-bottom:5px"
            >
            <b-form-input
                    v-model="titulo"
                    placeholder="Ingrese el titulo del video"
                    required
                    type="text"
                    />


            </b-col>

      
            <b-col
                       sm="12"
                       md="12"
                       lg="12"
                       xl="12"
                       style="margin-bottom: 10px;"
                     >
 
 
                     <p class="textLabel" style="text-align: left;margin-top: 0px;margin-bottom: 5px;">
                         Código embed del video (*):</p>
 
                         <b-form-textarea
                           v-model="embed"
                           placeholder="Ingrese el código embed"
                           rows="5"
                           required
                           no-resize
                        
                         />

                     </b-col>


          

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

           

              <p
                class="textLabel"
                style="text-align: center;margin-top: 0px;margin-bottom: 10px;"
              >
                Ingrese su código PIN (*): <feather-icon
                                  icon="HelpCircleIcon"
                                  size="16"
                                    v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                  title="Código PIN de seguridad"
                                  style="margin-right: 5px;cursor:pointer;"
                                  /> </p>

              <PincodeInput
                v-model="pin"
                placeholder="-"
                :length="6"
                :autofocus="false"
                :secure="true"
                :disabled="isDisabled"
               
                required
              />

            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

              <b-button
                id="btn_guardar"
                ref="btn_guardar"
                type="submit"
                variant="primary"
                block
                :disabled="isDisabled"
              >

               Registrar video

              </b-button>

            </b-col>

          </b-row>

          </b-form>

            </div>

            </b-modal>
    
    

    <b-row v-if="loading" style="margin-bottom:15px"> 
    
      <b-col  cols="10" sm="10" md="10" xl="10" lg="10">
    
    <h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
          <feather-icon
                    icon="ListIcon"
                    size="21"
                    class="color_icon"
                    /> Listado de videos tutoriales
            </h4>
    
    </b-col>
    
    <b-col  cols="2" sm="2" md="2" xl="2" lg="2">
    <b-skeleton width="100%"  height="20px"></b-skeleton>
    
    </b-col>
    
    
    
    <b-col  cols="4" sm="4" md="4" xl="4" lg="4">
    
    
    </b-col>
    
    <b-col  cols="4" sm="4" md="4" xl="4" lg="4">
    
    <b-skeleton width="100%"  height="30px"></b-skeleton>
    
    </b-col>
    
    <b-col  cols="4" sm="4" md="4" xl="4" lg="4">
    
    
    </b-col>
    
    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
        </b-col>
    
        <b-col  cols="6" sm="6" md="6" xl="6" lg="6">
    
        <b-skeleton width="100%"  height="30px"></b-skeleton>
    
        </b-col>
    
        <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
        </b-col>
    
    <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="margin-bottom:10px">
    
    
    
    
                      <b-skeleton-table
                  :rows="2"
                  :columns="columnas"
                  :table-props="{ bordered: true, striped: true }"
                ></b-skeleton-table>
    
    </b-col>
    
    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
    </b-col>
    
    <b-col  cols="6" sm="6" md="6" xl="6" lg="6">
    
    <b-skeleton width="100%"  height="30px"></b-skeleton>
    
    </b-col>
    
    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
    </b-col>
    
    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
    </b-col>
    
    <b-col  cols="2" sm="2" md="2" xl="2" lg="2">
    <b-skeleton width="100%"  height="30px"></b-skeleton>
    
    
    </b-col>
    
    <b-col  cols="2" sm="2" md="2" xl="2" lg="2" align="center">
    <b-skeleton width="30px" type="avatar"   height="30px"></b-skeleton>
    
    
    </b-col>
    
    <b-col  cols="2" sm="2" md="2" xl="2" lg="2">
    <b-skeleton width="100%"  height="30px"></b-skeleton>
    
    
    </b-col>
    
    
    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
    </b-col>
    
    
    
    </b-row>
    
    <b-row v-else-if="code200" style="  overflow: auto;">

     
    
    
        <b-col  cols="8" sm="8" md="8" xl="8" lg="8">
    
    <h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
          <feather-icon
                    icon="ListIcon"
                    size="21"
                    class="color_icon"
                    /> Listado de videos tutoriales
            </h4>
    
    </b-col>

    <b-col  cols="2" sm="2" md="2" xl="2" lg="2" style="    text-align: right;">
    
    
    <p style="cursor:pointer;    margin: 0;" @click="addTutorial()" > 
                                                                <feather-icon
                                                                icon="PlusIcon"
                                                                size="16"
                                                                style="margin-right:5px"
                                                                /> <span class="d-none d-sm-none d-md-inline-block d-lg-inline-block d-xl-inline-block"> Agregar </span> </p>
    
    
    
    </b-col>
    
    <b-col  cols="2" sm="2" md="2" xl="2" lg="2" style="    text-align: right;">
    
    
    <p style="cursor:pointer;    margin: 0;" @click="refresh()" > 
                                                                <feather-icon
                                                                icon="RefreshCcwIcon"
                                                                size="16"
                                                                style="margin-right:5px"
                                                                /> <span class="d-none d-sm-none d-md-inline-block d-lg-inline-block d-xl-inline-block"> Recargar </span> </p>
    
    
    
    </b-col>
    
    
    <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="  overflow: auto; padding: 0px; margin-top:15px">
    <table class="table tablesorter"  id="datatableTutoriales" >
                      <thead class="thead-light">
                        <tr>
                         
                          <th data-priority="1">Título</th>
                          <th data-priority="2">Acción</th>
                          <th>Video</th>
                         
                    
                        
                        </tr>
                      </thead>
                      <tbody>
    
    
                        <tr v-for="video in videos" :key="video.id">
    
                          <td> {{ video.title }}</td>
                        
                          <td>

                            <b-button
                                @click="editarTutorial(video.id,video.title, video.embed)"
                                variant="primary"
                                style="margin-right: 10px;margin-bottom: 10px;"
                                
                              >
                              <feather-icon
                              icon="EditIcon"
                              size="14"
                              />
                              </b-button>
                            
                            <b-button
                                @click="deleteTutorial(video.id,video.title)"
                                variant="outline-danger"
                                style="margin-right: 10px;margin-bottom: 10px;"
                              >
                              <feather-icon
                              icon="TrashIcon"
                              size="14"
                              />
                              </b-button>

                            </td>
                            <td> <div v-html="video.embed"></div></td>
    
                  
    
                        </tr>
                      </tbody>
                    </table>
    
                  </b-col>
    
    
      </b-row>

      <b-row v-else-if="notAllow">

<b-col  cols="12" sm="12" md="12" xl="12" lg="12" align="center">

  <img style="width:100px;" src="/img/caution.svg" />

  <p style="margin-top:10px; font-size:14px; ">Tu rol no te permite utilizar la siguiente funcionalidad.</p>


  </b-col>

</b-row>
    
    <b-row v-else-if="empty">
      <b-col  cols="12" sm="12" md="12" xl="12" lg="12">
    
    <h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
          <feather-icon
                    icon="ListIcon"
                    size="21"
                    class="color_icon"
                    /> Listado de videos tutoriales
            </h4>
    
    </b-col>
                          
    
      <b-col  align="center" cols="12" sm="12" md="12" xl="12" lg="12">
              <svg class="icons_colors" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g data-name="17. Withdraw" id="_17._Withdraw"><path d="M31 0H1A1 1 0 0 0 0 1V13a1 1 0 0 0 1 1H6V31a1 1 0 0 0 1 1H25a1 1 0 0 0 1-1V14h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zM2 2H30V4H2zM24 30H8V10H24zm6-18H26V10h1a1 1 0 0 0 0-2H5a1 1 0 0 0 0 2H6v2H2V6H30z"/><path d="M29 23a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V24a1 1 0 0 0-1-1z"/><path d="M3 16a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V17a1 1 0 0 0-1-1z"/><path d="M21 16h-.18A3 3 0 0 0 15 17a1 1 0 0 1-2 0V15a1 1 0 0 0-2 0v1a1 1 0 0 0 0 2h.18A3 3 0 0 0 17 17a1 1 0 0 1 2 0v2a1 1 0 0 0 2 0V18a1 1 0 0 0 0-2z"/><path d="M16 23a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0V24a1 1 0 0 0-1-1z"/></g></svg>                    
                                                      
    
                              <p class="errorTexto">
                            No se encontraron videos tutoriales registrados
                          </p>
    
                  </b-col>
    
      <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="    text-align: center;">
    
                                <b-button   variant="primary"  @click="addTutorial()" > 
                                              <feather-icon
                                              icon="PlusIcon"
                                              size="16"
                                              
                                              style="margin-right:5px; color:#fff"
                                              /> Registrar tutorial </b-button>
    
    
      </b-col>
      </b-row>
    </div> 
    </template>
        
        <script>
        import {
          VBTooltip, BButton,VBPopover,BForm, BRow, BCol,BSkeleton, BCard,BSkeletonTable, BFormInput, BAlert, BFormTextarea
        } from 'bootstrap-vue'
        import "jquery/dist/jquery.min.js";
        import PincodeInput from 'vue-pincode-input'

        import $ from "jquery";
        import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css'
        import 'datatables.net-responsive-dt/js/responsive.dataTables.min.js'
        
        export default {
          components: {
            BButton,
            BForm,
            PincodeInput,
            BSkeleton,
            BFormInput,
            BAlert,
            BSkeletonTable,
            BRow,
            BCol,
            BCard,
            BFormTextarea
          },
          directives: {
          'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
          props: ['userId','tokenAuth', 'idAdmin'],
          data() {
        
            const mq = window.matchMedia( "(min-width: 990px)" );
        let columnas=6;
    
        if (!mq.matches) {
    
          columnas =3;
          }
    
        
            return {
              code200: false,
              loading: true,
               empty:false,
               isDisabled:false,
               columnas:columnas,
               videos:[],
               id:"No",
               titulo:"",
               embed:"",
               pin:"",
               notAllow:false,
               title:"",

        
        
            }
        
        
          },
          computed: {
        
          },
          watch: {
        
          },
          mounted() {

            this.loadTable();

        
          },
          methods: {

            onSubmit(event) {

                        
            event.preventDefault()

            
            const data_json1 = { userId: this.userId,pin:this.pin, idAdmin:this.idAdmin,  id:this.id }
            const data_json2 = { titulo:this.titulo, embed:this.embed  }
            
            
            const data_string1 = JSON.stringify(data_json1)
            const data_string2 = JSON.stringify(data_json2)
            const dataEncripted1 = this.$encryptAdmin.encrypt(data_string1)
            const dataEncripted2 = this.$encryptAdmin.encrypt(data_string2)

            this.isDisabled = true

                document.getElementById('btn_guardar').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Guardando'

                this.$https.post('/administracion/uploadTutorial/', { tokenAuth: this.tokenAuth, data1: dataEncripted1, data2: dataEncripted2}).then(response => {
                this.isDisabled = false



                document.getElementById('btn_guardar').innerHTML = 'Registrar video';
             

                if (response.data.code == 200) {

                  

                    this.$refs.modalTutorial.hide();

                    this.refresh();
                       
                       this.$toast.success(response.data.message, {
                         position: 'top-right',
                         timeout: 3010,
                         closeOnClick: true,
                         pauseOnFocusLoss: false,
                         pauseOnHover: true,
                         draggable: true,
                         draggablePercent: 0.4,
                         showCloseButtonOnHover: false,
                         hideProgressBar: true,
                         closeButton: 'button',
                         icon: true,
                         rtl: false,
                       })

                  
                    

                } else {
                    this.isDisabled = false

                    this.$toast.error(response.data.message, {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                    })

                    if (response.data.code == 401) {
                    
                    localStorage.removeItem('userData')



                         // Redirect to login page
                         this.$router.push({ name: 'auth-login' })

                    } else if (response.data.code == 503) {
                    this.pin = ''
                    }
                }
                }).catch(error => {
                  this.isDisabled = false
                  document.getElementById('btn_guardar').innerHTML = 'Registrar video';
             
                this.$toast.error("Ocurrió un error inesperado", {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                })
                })
                

            },

            editarTutorial(id, title, embed){

          
                this.id=id;
                this.title="Editar video tutorial";
                this.titulo=title;
                this.embed=embed;
                this.pin="";
                this.$refs.modalTutorial.show();
  
        
    
            },

            addTutorial(){
                this.id="No";
                this.titulo="";
                this.title="Registrar nuevo tutorial";
                this.embed="";
                this.pin="";
                this.$refs.modalTutorial.show()
            },
        
            refresh() {
              this.$eventBus.$emit('reiniciarTutorialesAdmin')
            },deleteTutorial(id, tituloVideo){
    

              let title="";
              let textButton="";
              let html="";
              html='<p style="text-align:center; font-weight:bold">'+tituloVideo+'</p>'
                    
    
                title='¿Estas seguro que deseas eliminar este tutorial?';
                 textButton="Si, eliminar";
    
                this.$swal({
                title: title,
                html: html,
                input: 'text',
                inputPlaceholder: "Motivo de eliminación del tutorial",
                inputAttributes: {
                    autocapitalize: 'off'
                },
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: textButton,
                cancelButtonText: 'Cancelar',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-secondary ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: (motivo) => {

                    if (motivo != "") {

                        const data_json = { userId: this.userId,motivo:motivo, id:id,idAdmin:this.idAdmin }
                            const data_string = JSON.stringify(data_json)
                
                        
                
                            const dataEncripted = this.$encryptAdmin.encrypt(data_string)
                
                            return this.$https.post('/administracion/deleteTutorial/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => response.data).catch(error => {
                                this.$swal.showValidationMessage(
                                error,
                                )
                            })


                    } else {
                        this.$swal.showValidationMessage('El motivo de la eliminación es obligatorio')   
                   }
               
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
              }).then(result => {
    
                this.$swal.close()
    
                if(result.isConfirmed){
    
       
    
    
    
                  if (result.value.code == 200) {
    
                    this.refresh();
                       
                            this.$toast.success(result.value.message, {
                              position: 'top-right',
                              timeout: 3010,
                              closeOnClick: true,
                              pauseOnFocusLoss: false,
                              pauseOnHover: true,
                              draggable: true,
                              draggablePercent: 0.4,
                              showCloseButtonOnHover: false,
                              hideProgressBar: true,
                              closeButton: 'button',
                              icon: true,
                              rtl: false,
                            })
    
    
            
                          } else {
                            this.$toast.error(result.value.message, {
                              position: 'top-right',
                              timeout: 3010,
                              closeOnClick: true,
                              pauseOnFocusLoss: false,
                              pauseOnHover: true,
                              draggable: true,
                              draggablePercent: 0.4,
                              showCloseButtonOnHover: false,
                              hideProgressBar: true,
                              closeButton: 'button',
                              icon: true,
                              rtl: false,
                            })
    
                            if (result.data.code == 401) {
                              
                              localStorage.removeItem('userData')


    
                              
                              
    
                  
    
                              
                              
    
                              // Redirect to login page
                              this.$router.push({ name: 'auth-login' })
                            }
                          }
    
                }
                
    
    
    
          
              })
    
           
    
    
            },loadTable(){
              const data_json = { userId: this.userId, idAdmin: this.idAdmin }
              const data_string = JSON.stringify(data_json)
    
        
              const dataEncripted = this.$encryptAdmin.encrypt(data_string);
    
                this.$https.post('/administracion/loadTutoriales/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
    
                  this.loading=false;

           
    
                    
                  if (response.data.code == 200) {
                    this.videos= response.data["videos"];
                
                                
                        setTimeout(() => {
                          $("#datatableTutoriales").DataTable({
                            responsive: true,
                            "ordering": false,
                            columnDefs: [
                                                    { responsivePriority: 1, targets: 0 },
                                                    { responsivePriority: 2, targets: -1 }
                                                ],
                            lengthMenu: [
                              [2,5,15,25, 35, 50, -1],
                              [2,5,15,25, 35, 50, "All"],
                            ],
                            pageLength: 2, 
                               "language": {
                                                  "search": "Buscar:",
                                                  "sInfo":      "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                                                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                                  "searchPlaceholder": "Ingrese algún dato",
                                                  "zeroRecords": "Lo sentimos, no se encontraron resultados",
                                                  "processing": "Buscando. Espere por favor...",
                                                  "sLengthMenu":     "Mostrar _MENU_ registros",
                                                  "oPaginate": {
                                                          "sFirst":    "Primero",
                                                          "sLast":     "Último",
                                                          "sNext":     "Siguiente",
                                                          "sPrevious": "Anterior"
                                                      },
    
                                                  }
                          });
                        });
    
                            this.code200=true;
    
                  
                    
    
    
                    
                  } else {
                    
                        
    
    
                    if (response.data.code == 401) {
    
                        this.$toast.error(response.data.message, {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })
    
                        
                        localStorage.removeItem('userData')


    
                        
    
    
    
    
                        
                        
    
                        // Redirect to login page
                        this.$router.push({ name: 'auth-login' })
                        } else {
    
                          if (response.data.code == 403) {
    
                            this.$router.push({ name: 'dashboard-analytics' })
    
                            }else{

                              if(response.data.code === 501){

                              this.notAllow=true;

                              }else{
                                if (response.data.code == 404) {
                                    
                                    
                                    
                                    this.empty=true;

                                    }else{

                                      this.$toast.error(response.data.message, {
                                        position: 'top-right',
                                        timeout: 3010,
                                        closeOnClick: true,
                                        pauseOnFocusLoss: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        draggablePercent: 0.4,
                                        showCloseButtonOnHover: false,
                                        hideProgressBar: true,
                                        closeButton: 'button',
                                        icon: true,
                                        rtl: false,
                                      })

                                      this.code200 = false

                                    }

                                }
                           
    
                            }
    
                  
    
    
    
                        } 
                      
                    
                  }
                }).catch(error => {
                    this.loadTable();
                })
            
            }
        
        
          },
        }
        </script>
        
        <style lang="scss">
        
        
        </style>