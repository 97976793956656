<template>


    <b-row>

      <b-col  cols="12" sm="12" md="12" xl="6" lg="12">

        
      <b-card>

        <b-row>

          <b-col  cols="12" sm="12" md="12" xl="12" lg="12">
    
    <h4 class="font-weight-bolder" style="text-align: left !important;    margin-bottom: 15px;">
        <feather-icon
                    icon="ListIcon"
                    size="21"
                    class="color_icon"
                    /> Búsqueda de transacciones 
            </h4>
    
    </b-col>        

    
    <b-col  cols="12" sm="12" md="12" xl="12" lg="12">





        <b-form
        autocomplete="off"
        @submit.prevent="onSubmit"
        
      >

      <b-row>

        <b-col  cols="12" sm="12" md="12" xl="8" lg="8" style="margin-bottom:15px">

  
          
            <b-form-input
            v-model="search"
            placeholder="Ingrese el id de la transacción del usuario"
            required
            :readonly="isDisabled"
            type="text"
            />


        </b-col>

        <b-col  cols="12" sm="12" md="12" xl="4" lg="4" >

            <b-button
                id="btn_search"
                ref="btn_search"
                type="submit"
                variant="primary"
                :disabled="isDisabled"
                block
            >

             Buscar

      </b-button>


        </b-col>

      </b-row>

      
     

      </b-form>





    </b-col>



    <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="margin-top:15px">




        <div class="table-responsive ">
                 <table class="table table-bordered" style="text-align: center;">

                  <tbody>
                    <tr>
           
                      <th class="background_table2">Origen</th>
                      <th class="background_table2">Fecha</th>
                      <th class="background_table2">Before Balance</th>
                     
                     

                    
                    </tr>

                    <tr v-if="isSearch">
                        <td>---</td>
                        <td>---</td>
                        <td>---</td>
                      
              
                    
                    </tr>

                      <tr v-else>
                        <td class="sin_fontweigth">{{source}}</td>
                        <td class="sin_fontweigth">{{dateFormated}}</td>
                        <td  class="sin_fontweigth">
                            ${{beforeBalance}}
                        </td>
                     
                      
                   
                    </tr>


                    <tr>
                      <th class="background_table2">Balance</th>
                        <th class="background_table2">Estado</th>
                        <th class="background_table2">Email de quien recibe</th>
                     
              

                      
                      </tr>

                      <tr v-if="isSearch">
                          <td>---</td>
                          <td>---</td>
                          <td>---</td>
                        
                
                      
                      </tr>

                        <tr v-else>
                          <td class="sin_fontweigth">${{balance}}</td>
                          <td v-if="status === 'Disponible'">
                             <b-badge variant="success">
                                {{status}}
                              </b-badge>
                          
                          </td>
                          <td v-else-if="status === 'Pendiente'">

                            <b-badge variant="warning">
                              {{status}}
                            </b-badge>


                            </td>
                          <td v-else>

                             <b-badge variant="danger">
                                {{status}}
                              </b-badge>
                           
                            
                            </td>
                          <td v-if="email == ''" class="sin_fontweigth">---</td>
                          <td v-else class="sin_fontweigth">{{email}}</td>
                        
                      </tr>


                      <tr>
                            
                            <th class="background_table2">Quien envia</th>
                            <th class="background_table2">Quien recibe</th>
                            <th class="background_table2">Monto</th>
                          
                  

                          
                          </tr>

                          <tr v-if="isSearch">
                              <td>---</td>
                              <td>---</td>
                              <td>---</td>
                             
                    
                          
                          </tr>

                            <tr v-else>
                              <td v-if="displayNameSendMoney != ''" class="sin_fontweigth">{{displayNameSendMoney}}

                                <b-input-group v-if="userIdWhoPay != ''">
                                        
                                        <b-form-input
                                        v-model="userIdWhoPay"
                                        
                                        
                                            readonly>
                                            
                                            </b-form-input>

                                        <b-input-group-append>
                                            <b-button
                                            @click="onCopy1(userIdWhoPay)"
                                            variant="outline-primary"
                                            >

                                            <feather-icon
                                        icon="CopyIcon"
                                        size="15"

                                        /> 
                                            </b-button>
                                        </b-input-group-append>
                                        </b-input-group>
                              </td>

                              <td v-else>---</td>

                                <td v-if="displayNameReceiveMoney != ''" class="sin_fontweigth">{{displayNameReceiveMoney}}

                                  <b-input-group  v-if="userIdWhoReceive != ''">
                                          
                                          <b-form-input
                                          v-model="userIdWhoReceive"
                                          
                                          
                                              readonly>
                                              
                                              </b-form-input>

                                          <b-input-group-append>
                                              <b-button
                                              @click="onCopy1(userIdWhoReceive)"
                                           
                                              variant="outline-primary"
                                              >

                                              <feather-icon
                                          icon="CopyIcon"
                                          size="15"

                                          /> 
                                              </b-button>
                                          </b-input-group-append>
                                          </b-input-group>
                                  </td>

                                  <td v-else>---</td>

                            
                              <td  class="sin_fontweigth">
                                  ${{mount}}
                              </td>
                           

                          </tr>

                          <tr>
                            
                            <th class="background_table2">Email de quien envió el dinero</th>
                            <th class="background_table2">Celular de quien envió el dinero</th>
                            <th class="background_table2">DNI de quien envió el dinero</th>
                  

                          
                          </tr>

                          <tr v-if="isSearch">
                              <td>---</td>
                              <td>---</td>
                              <td>---</td>
                    
                          
                          </tr>

                          <tr v-else>

                            <td>{{ emailWhoPay }}</td>
                              <td>{{ phoneWhoPay }}</td>
                              <td>{{ dniWhoPay }}</td>
                            </tr>


                            <tr>
                            
                              <th class="background_table2">Celular de quien recibe</th>
                              <th class="background_table2">Tipo</th>

                              <th class="background_table2">Monto real</th>

                          
                          </tr>

                          <tr v-if="isSearch">
                              <td>---</td>
                              <td>---</td>
                              <td>---</td>
                    
                          
                          </tr>

                          <tr v-else>

                                                      
                            <td v-if="phone == ''" class="sin_fontweigth">---</td>
                            <td v-else class="sin_fontweigth">{{phone}}</td>
                            <td v-if="type == 'Receive Money'" class="sin_fontweigth">Recibo de dinero</td>
                            <td v-else class="sin_fontweigth">Envio de dinero</td>



                            <td class="sin_fontweigth">${{mount_real}}</td>

                            </tr>

                   

             
                  
                  </tbody>
                </table>
                </div>

                <b-row>

                </b-row>


    </b-col>

        </b-row>
        </b-card>


        </b-col>

        <b-col  cols="12" sm="12" md="12" xl="6" lg="12">

          <b-card>


          <transacciones-in-transit
              :key="componentKeyTransaccionesInTransit"
              :user-id="userId"
              :token-auth="tokenAuth"
              :id-admin="idAdmin"
            />

            </b-card>
            

        </b-col>

     
      </b-row>
        
    </template>
        
        <script>
        import {
          BButton,BBadge, BRow,BCol,BSkeleton,BCard, BFormInput, BForm,VBPopover, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormGroup, 
        } from 'bootstrap-vue'
        import TransaccionesInTransit from './TransaccionesInTransit.vue'
        export default {
          components: {
            BButton,
            BSkeleton,
            BFormInput,
            TransaccionesInTransit,
            BRow,
            BForm,
            BCol,
            BBadge,
            BCard,
            BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormGroup, 
          },
          directives: {
            'b-popover': VBPopover,
          },
          props: ['userId','tokenAuth', 'idAdmin'],
          data() {
        

        
            return {
              componentKeyTransaccionesInTransit:0,
                source:"",
                isDisabled:false,
                search:"",
                beforeBalance:"",
                balance:"",
                dateFormated:"",
                isSearch:true,
                status:"",
                email:"",
                phone:"",
                mount_real:"",
                phoneWhoPay:"",
                emailWhoPay:"",
                dniWhoPay:"",
                mount:"",
                displayNameSendMoney:"",
                displayNameReceiveMoney:"",
                userIdWhoPay:"",
                userIdWhoReceive:"",
                type:"",
                

            }
        
        
          },
          computed: {
        
          },
          watch: {
        
          },
          mounted() {
    
    
            this.$eventBus.$on('reiniciarListadoTransaccionesInTransit', () => {
              this.componentKeyTransaccionesInTransit += 1
              
            });
  

        
          },
          methods: {


            
    onCopy1(copy) {
      navigator.clipboard.writeText(copy);
      this.$toast.success('Texto copiado!', {
        position: 'top-right',
        timeout: 1500,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
    },


            onSubmit(event){
                event.preventDefault();

                this.isDisabled= true;


              

                const data_json = { userId: this.userId, idAdmin: this.idAdmin, search: this.search }
                    const data_string = JSON.stringify(data_json)

                    document.getElementById('btn_search').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Buscando'

                    const dataEncripted = this.$encryptAdmin.encrypt(data_string);

                    this.$https.post('/administracion/searchTransactions/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {

                        this.isDisabled=false;
                        this.search="";


                        document.getElementById('btn_search').innerHTML = 'Buscar transacción';

                   
                        this.isSearch=true;

                     
                        
                        if (response.data.code == 200) {
                            this.isSearch=false;
              
                   
                            this.source= response.data["datos"]["source"];
                            this.balance= response.data["datos"]["balance"];
                            this.beforeBalance= response.data["datos"]["beforeBalance"];
                            this.dateFormated= response.data["datos"]["dateFormated"];

                            this.status= response.data["datos"]["status"];
                            this.email= response.data["datos"]["email"];
                            this.phone= response.data["datos"]["phone"];

                            this.mount_real= response.data["datos"]["mount_real"];
                            this.phoneWhoPay= response.data["datos"]["phoneWhoPay"];
                            this.emailWhoPay= response.data["datos"]["emailWhoPay"];
                            this.dniWhoPay= response.data["datos"]["dniWhoPay"];
                
                            this.mount= response.data["datos"]["mount"];
                            this.displayNameSendMoney= response.data["datos"]["displayNameSendMoney"];
                            this.displayNameReceiveMoney= response.data["datos"]["displayNameReceiveMoney"];

                            this.userIdWhoPay=  response.data["datos"]["userIdWhoPay"];
                            this.userIdWhoReceive= response.data["datos"]["userIdWhoReceive"];

                            this.type= response.data["datos"]["type"];

                        
                        } else {
                        
                            


                        if (response.data.code == 401) {

                            this.$toast.error(response.data.message, {
                                position: 'top-right',
                                timeout: 3010,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.4,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: 'button',
                                icon: true,
                                rtl: false,
                            })

                            
                            localStorage.removeItem('userData')

                           

                            




                            
                            

                            // Redirect to login page
                            this.$router.push({ name: 'auth-login' })
                            } else {

                                if (response.data.code == 403) {

                                this.$router.push({ name: 'dashboard-analytics' })

                                }else{

                                  this.$toast.error(response.data.message, {
                                        position: 'top-right',
                                        timeout: 3010,
                                        closeOnClick: true,
                                        pauseOnFocusLoss: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        draggablePercent: 0.4,
                                        showCloseButtonOnHover: false,
                                        hideProgressBar: true,
                                        closeButton: 'button',
                                        icon: true,
                                        rtl: false,
                                        })

                                }

                        



                            } 
                            
                        
                        }
                    }).catch(error => {
                      this.$toast.error("Ocurrió un error inesperado", {
                                        position: 'top-right',
                                        timeout: 3010,
                                        closeOnClick: true,
                                        pauseOnFocusLoss: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        draggablePercent: 0.4,
                                        showCloseButtonOnHover: false,
                                        hideProgressBar: true,
                                        closeButton: 'button',
                                        icon: true,
                                        rtl: false,
                                        })
                    })

            },
         
        
       
        }
    }
        </script>
        
        <style lang="scss">
        .sin_fontweigth{
          font-weight: 100;
        }
        
        </style>