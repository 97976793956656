<template>

    <div>
     
   <vue-html2pdf
                 :show-layout="false"
                 :float-layout="true"
                 :enable-download="true"
                 :preview-modal="false"
                 :paginate-elements-by-height="1400"
                 :filename="idTransaction"
                 :pdf-quality="2"
                 :manual-pagination="false"
                 pdf-format="a5"
                 pdf-orientation="portrait"
                 pdf-content-width="580px"
                 @progress="onProgress($event)"
                 @hasDownloaded="hasDownloaded($event)"
                 ref="myPdf"
             >
                 <section slot="pdf-content">
                    <b-row style="padding:25px">
                     <b-col cols="12" sm="12" md="12" lg="12" xl="12">
 
                       <p style="color:#000 !important;text-align: right;">{{ dateFormated }}</p>
 
                     </b-col>
 
                     <b-col cols="12" sm="12" md="12" lg="12" xl="12" align="center" style="margin-bottom:25px">
 
                         
                       <img
                         width="150px"
                         src="/img/eqcoop.svg"
 
                         >
 
                         </b-col>
 
                         <b-col cols="12" sm="12" md="12" lg="12" xl="12">
 
                         <p style="color:#000 !important;font-weight: bold;font-size:16px; text-align: center;margin-bottom:0px; margin-top:10px">Agente recaudador EQ PAY SYSTEMS LLC</p>
                         <p style="color:#000 !important;font-style:italic;font-size:12px; text-align: center;margin:0px">Dirección: 5900 NW 97TH AVE STE C21, DORAL FL 33178</p>
                         <p style="color:#000 !important;font-style:italic;font-size:12px; text-align: center;margin:0px">Employer Identification Number: 32-0696902</p>
                         <p style="color:#000 !important;font-style:italic;font-size:12px; text-align: center;margin:0px">www.eqcoops.com</p>
                         <p style="color:#000 !important;font-style:italic;font-size:12px; text-align: center;margin-bottom:0px">finanzas@eqcoops.com</p>
 
                         </b-col> 
 
                         <b-col cols="5" sm="5" md="5" lg="5" xl="5" align="center">
 
                           <b-avatar
                                       size="60"
                                       :src="photoSend"
                                       variant="light-primary"
                                     ></b-avatar>
 
         
 
                           <p style="color:#000 !important; font-size: 12px;margin-bottom:0px; margin-top:5px;white-space: nowrap;text-overflow: ellipsis; overflow: hidden;">{{ displayNameSendMoney }}</p>
                           </b-col>
 
                           <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="display:flex">
 
                             <div style="margin:auto">
                               <feather-icon  icon="ArrowRightCircleIcon" size="25"/> 
                             </div>
 
                             
 
                           </b-col>
 
                           <b-col cols="5" sm="5" md="5" lg="5" xl="5" align="center">
                             <b-avatar
                                       size="60"
                                       :src="photoReceive"
                                       variant="light-primary"
                                     ></b-avatar>
                          
 
                             <p style="color:#000 !important;font-size: 12px;margin-bottom:0px; margin-top:5px;white-space: nowrap;text-overflow: ellipsis; overflow: hidden;"> {{ displayNameReceiveMoney }}</p>
                           </b-col>
 
 
                      
 
                      
 
                       <b-col cols="12" sm="12" md="12" lg="12" xl="12">
 
                       <p style="color:#000 !important;font-size: 12px;margin:5px"><span style="font-weight: bold;">ID: </span><span style="font-size: 12px;">{{ idTransaction }} </span> </p>
                       <p style="color:#000 !important;font-size: 12px;margin:5px"><span style="font-weight: bold;">Authorization Code:</span> {{ tokenRecibe }}</p>
 
                       <p style="color:#000 !important;font-size: 12px;font-weight: bold;text-align: center;margin-top:20px">Detalles de la transacción</p>
                       <p style="color:#000 !important; font-size: 12px;margin:5px"><span style="font-weight: bold;">Descripción del pago: </span> Pago por concepto de {{ source }}</p>
                       <p style="color:#000 !important; font-size: 12px;margin:5px"><span style="font-weight: bold;">Monto recibido: </span>${{ mount }}</p>
 
                       <p style="color:#000 !important; font-size: 11px;margin-top:25px; margin-bottom:10px">Debo y pagaré al emisor incondicionalmente y sin protesto el total de este pagaré más los intereses y cargos por este servicio. En caso de mora, pagaré la tasa máxima autorizada para el emisor. Declaro que el producto de la transacción no será utilizado en actividades de lavado de activos, financimianeto de terrorismo, comercio ilegal de armas, animales, personas, prostitución, pornografía, drogas ilícitas y otros delitos. Declaro entender y aceptar los Términos y Condiciones de Pago de EQCoop.</p>
               
                       <p style="color:#000 !important;font-weight: bold;text-align: center; font-size: 12px;margin-top:45px; margin-bottom:0px">_________________________________________</p>
 
                       <p style="color:#000 !important;font-weight: bold;text-align: center; font-size: 11px;margin-top:0px; margin-bottom:0px"> Firma del receptor del pago</p>
 
                     </b-col>
 
                   
                    </b-row>
                 </section>
             </vue-html2pdf>
    </div>
       
       </template>
       
       <script>
       import {
         BButton, BRow, VBPopover, BCol, BCard, BAvatar
       } from 'bootstrap-vue'
       
       import VueHtml2pdf from 'vue-html2pdf'
       
       export default {
         components: {
           BAvatar,
             VueHtml2pdf,
           BButton,
           BRow,
           BCol,
           BCard,
   
         },
         directives: {
           'b-popover': VBPopover,
         },
         props: ['idTransaction','userId','tokenAuth', 'dateFormated','displayNameReceiveMoney','displayNameSendMoney','token', 'source','mount', 'photoSendMoney','photoReceiveMoney'],
         data() {
 
           let tokenRecibe="";
 
           let photoSend="";
           let photoReceive="";
 
 
           if(this.token != ""){
 
             tokenRecibe=this.token;
 
           }else{
             tokenRecibe="----";
           }
 
           if(this.photoSendMoney != ""){
 
             photoSend=this.photoSendMoney;
 
           }else{
             photoSend="----";
           }
 
           if(this.photoReceiveMoney != ""){
 
             photoReceive=this.photoReceiveMoney;
 
             }else{
               photoReceive="----";
             }
       
       
           return {
           tokenRecibe:tokenRecibe,
           photoReceive:photoReceive,
           photoSend:photoSend
 
           }
         },
         computed: {
       
         },
         watch: {
       
         },
         mounted() {
             this.generateReport();
 
 
         },
         methods: {
             generateReport () {
             this.$refs.myPdf.generatePdf()
         },
 
         onProgress(event){
           this.$swal({
 
               html:
               '<img style="margin-left:10px; width: 30px" src="/img/loading.svg" alt="Loading" /> Generando comprobante, espere',
               showCloseButton: false,
               showCancelButton: false,
               focusConfirm: false,
               allowEscapeKey: false,
               allowOutsideClick: false,
               showConfirmButton: false,
               position: 'center',
               })
           
 
         },  hasStartedGeneration(){
      
 
      
 
         },  hasDownloaded(event){
           this.$swal.close()
           
           this.$eventBus.$emit('reiniciarPdf')
 
         },
       
          
       
         },
       }
       </script>
       
       <style scoped>
 
       
       
       
       </style>
       