<template>

<b-card v-if="loading">
    <b-row  style="margin-bottom:15px"> 

  
    
      <b-col  cols="10" sm="10" md="8" xl="8" lg="8">
    
    <h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
          <feather-icon
                    icon="ListIcon"
                    size="21"
                    class="color_icon"
                    /> Remesas pendientes
            </h4>
    
    </b-col>
    
    
    <b-col  cols="1" sm="1" md="2" xl="2" lg="2" style="    text-align: center;">
    <b-skeleton width="100%"  height="20px"></b-skeleton>
    
    </b-col>
    
    <b-col  cols="1" sm="1" md="2" xl="2" lg="2" style="    text-align: center;">
      <b-skeleton width="100%"  height="20px"></b-skeleton>
    </b-col>
    
    <b-col  cols="4" sm="4" md="4" xl="4" lg="4">
    
    
    </b-col>
    
    <b-col  cols="4" sm="4" md="4" xl="4" lg="4">
    
    <b-skeleton width="100%"  height="30px"></b-skeleton>
    
    </b-col>
    
    <b-col  cols="4" sm="4" md="4" xl="4" lg="4">
    
    
    </b-col>
    
    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
        </b-col>
    
        <b-col  cols="6" sm="6" md="6" xl="6" lg="6">
    
        <b-skeleton width="100%"  height="30px"></b-skeleton>
    
        </b-col>
    
        <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
        </b-col>
    
    <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="margin-bottom:10px">
    
    
    
    
                      <b-skeleton-table
                  :rows="2"
                  :columns="columnas"
                  :table-props="{ bordered: true, striped: true }"
                ></b-skeleton-table>
    
    </b-col>
    
    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
    </b-col>
    
    <b-col  cols="6" sm="6" md="6" xl="6" lg="6">
    
    <b-skeleton width="100%"  height="30px"></b-skeleton>
    
    </b-col>
    
    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
    </b-col>
    
    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
    </b-col>
    
    <b-col  cols="2" sm="2" md="2" xl="2" lg="2">
    <b-skeleton width="100%"  height="30px"></b-skeleton>
    
    
    </b-col>
    
    <b-col  cols="2" sm="2" md="2" xl="2" lg="2" align="center">
    <b-skeleton width="30px" type="avatar"   height="30px"></b-skeleton>
    
    
    </b-col>
    
    <b-col  cols="2" sm="2" md="2" xl="2" lg="2">
    <b-skeleton width="100%"  height="30px"></b-skeleton>
    
    
    </b-col>
    
    
    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
    </b-col>
    
   
    
    </b-row>

  </b-card>
    
    <b-card v-else-if="code200" style="  overflow: auto;">
    
      <b-modal
          ref="modalDatos"
          centered
          hide-footer
          title="Detalles de la remesa"
        >
          <div class="d-block text-center">
  
    
            <b-row>
    
            
    
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
    
                <h4 class="font-weight-bolder">
                  Datos de quien envia
                </h4>
               
    
              </b-col>
    
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
    
                <p class="p_text"><span class="textLabel">Usuario: </span>{{displayNameWhoSend}}</p>
    
    
                </b-col>
    
                <b-col cols="12" sm="12" md="12" lg="4" xl="4">
    
                <p class="p_text"><span class="textLabel">DNI: </span>{{dniWhoSend}}</p>
    
    
                </b-col>
    
                <b-col cols="12" sm="12" md="12" lg="8" xl="8">
    
                <p class="p_text"><span class="textLabel">Contacto: </span>{{contactWhoSend}}</p>
    
    
                </b-col>
    
    
    
    
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
    
                  <hr class="mb-2">
                  <h4 class="font-weight-bolder">
                  Datos de quien recibe
                </h4>
              
    
                </b-col>
    
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
    
                <p class="p_text"><span class="textLabel">Usuario: </span>{{displayNameWhoReceive}}</p>
    
    
                </b-col>
    
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
    
                <p class="p_text"><span class="textLabel">Pais: </span>{{countryContact}}</p>
    
    
                </b-col>
    
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
    
                <p class="p_text"><span class="textLabel">DNI: </span>{{dniContact}}</p>
    
    
                </b-col>

                <b-col cols="12" sm="12" md="12" lg="5" xl="5">
    
                    <p class="p_text"><span class="textLabel">Celular: </span>{{phoneNumberContact}}</p>


                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="7" xl="7">
    
                        <p class="p_text"><span class="textLabel">Email: </span>{{emailContact}}</p>


                        </b-col>

                        <b-col cols="12" sm="12" md="12" lg="12" xl="12">
    
                        <p class="p_text"><span class="textLabel">Banco: </span>{{nameBankContact}}</p>


                        </b-col>

                        <b-col cols="12" sm="12" md="12" lg="6" xl="6">
    
                            <p class="p_text"><span class="textLabel">Nro. cuenta: </span>{{numberAccountContact}}</p>


                            </b-col>

                            <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                            
                            <p class="p_text"><span class="textLabel">Tipo: </span>{{typeAccountContact}}</p>


                            </b-col>
                        
    
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
    
                  <hr class="mb-2">
                  
                  <h4 class="font-weight-bolder">
                  Datos de la remesa
                </h4>
                
    
                  </b-col>

                  <b-col cols="12" sm="12" md="12" lg="6" xl="6">
    
                    <p class="p_text"><span class="textLabel">Transacción ID: </span> {{transactionsId}}</p>


                    </b-col>
    
                  <b-col cols="12" sm="12" md="12" lg="6" xl="6">
    
                  <p class="p_text"><span class="textLabel">Fecha: </span>{{date}}</p>
    
    
                  </b-col>
    
                  <b-col cols="12" sm="12" md="12" lg="6" xl="6">
    
                  <p class="p_text"><span class="textLabel">Monto Real: </span>$ {{mountReal}} USD</p>
    
    
                  </b-col>

                  <b-col cols="12" sm="12" md="12" lg="6" xl="6">
    
                    <p class="p_text"><span class="textLabel">Rate: </span>{{currency}} {{rate}} </p>


                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="6" xl="6">
    
                      <p class="p_text"><span class="textLabel">Monto - comisión: </span>$ {{mount}} USD</p>


                      </b-col>
    
                  <b-col cols="12" sm="12" md="12" lg="6" xl="6">
    
                  <p class="p_text"><span class="textLabel">Monto remesa: </span>{{currency}} {{mountRemittance}}</p>
    
    
                  </b-col>

            
    
               
    
            </b-row>
      
           
    
          </div>
    
        </b-modal>

  
    
            <b-row>

              <b-col  cols="10" sm="10" md="8" xl="8" lg="8">
    
                      <h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
                            <feather-icon
                                      icon="ListIcon"
                                      size="21"
                                      class="color_icon"
                                      /> Remesas pendientes
                              </h4>
                      
                      </b-col>

                      <b-col  cols="1" sm="1" md="2" xl="2" lg="2" style="    text-align: center;">
                      
                      
                      <p style="cursor:pointer;    margin: 0;" @click="download()" > 
                                                                                  <feather-icon
                                                                                  icon="DownloadCloudIcon"
                                                                                  size="16"
                                                                                  style="margin-right:5px"
                                                                                  /> <span class="d-none d-sm-none d-md-inline-block d-lg-inline-block d-xl-inline-block"> Descargar txt </span> </p>
                      
                      
                      
                      </b-col>


                      
                      <b-col  cols="1" sm="1" md="2" xl="2" lg="2" style="    text-align: center;">
                      
                      
                      <p style="cursor:pointer;    margin: 0;" @click="refresh()" > 
                                                                                  <feather-icon
                                                                                  icon="RefreshCcwIcon"
                                                                                  size="16"
                                                                                  style="margin-right:5px"
                                                                                  /> <span class="d-none d-sm-none d-md-inline-block d-lg-inline-block d-xl-inline-block"> Recargar </span> </p>
                      
                      
                      
                      </b-col>



                      
                      <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="  overflow: auto; padding: 0px; margin-top:15px">
                      <table class="table tablesorter"  id="datatableRemesas" >
                                        <thead class="thead-light">
                                          <tr>
                                            <th data-priority="1">Fecha/hora</th>
                             
                                            <th>País</th>
                                            <th>Monto</th>
                                            <th>Rate</th>
                                            <th>Monto remesa</th>
                                          
                                            <th>Quién envia</th>
                                            <th>Quién recibe</th>
                                            
                                            
                                            <th data-priority="2">Acción</th>
                                          
                                          </tr>
                                        </thead>
                                        <tbody>
                      
                      
                                          <tr v-for="transaccion in transactions" :key="transaccion.idTransaction">
                      
                                            <td> {{ transaccion.dateFormated }}</td>
                                     
                                            <td> {{ transaccion.countryCodeContact }}</td>
                                            <td> $ {{ transaccion.mount }} USD</td>
                                            <td> {{ transaccion.currency }} {{ transaccion.rate }}</td>
                                            <td> {{ transaccion.currency }} {{ transaccion.mountRemittance }}</td>
                                            
                                          
                      
                      
                                  
                      
                                              <td>  {{ transaccion.displayNameWhoSend }}</td>
                      
                                              <td> {{ transaccion.displayNameWhoReceive }}</td>
                      
                                              <td>
                      
                                                <b-dropdown  dropleft variant="light" size="sm"  no-caret>
                                                                    <template  #button-content>
                      
                                                                    <feather-icon
                                                                      icon="MoreVerticalIcon"
                                                                      size="25"
                                                                      class="MoreVerticalIcon"
                                                                      />
                                                                      
                      
                      
                                                                    </template>
                                                                    <b-dropdown-item @click="aprobarRechazar(transaccion.idTransaction,transaccion.userId, 'Aprobar', transaccion.displayNameWhoReceive, transaccion.displayNameWhoSend, transaccion.currency, transaccion.mountRemittance)">
                                                                      <feather-icon
                                                                      icon="CheckCircleIcon"
                                                                      size="15"
                                                                      style="margin-right: 10px;"
                                                                      />
                      
                                                                      Aprobar remesa
                                                                    </b-dropdown-item>
                                                                    <b-dropdown-item @click="aprobarRechazar(transaccion.idTransaction,transaccion.userId, 'Rechazar', transaccion.displayNameWhoReceive, transaccion.displayNameWhoSend, transaccion.currency, transaccion.mountRemittance)">
                                                                      <feather-icon
                                                                      icon="AlertOctagonIcon"
                                                                      size="15"
                                                                      style="margin-right: 10px;"
                                                                      />

                                  
                                      
                      
                                                                      Rechazar remesa
                                                                    </b-dropdown-item>
                                                                    <b-dropdown-item @click="verDatos(transaccion.countryContact, transaccion.dniContact,transaccion.phoneNumberContact,transaccion.emailContact,transaccion.nameBankContact,transaccion.numberAccountContact,transaccion.typeAccountContact,transaccion.displayNameWhoReceive,transaccion.contactWhoSend,transaccion.dniWhoSend,transaccion.displayNameWhoSend,transaccion.currency,transaccion.mountRemittance,transaccion.dateFormated, transaccion.transactionsId, transaccion.mount, transaccion.mountReal, transaccion.rate)">
                                                                      
                                                                      <feather-icon
                                                                      icon="EyeIcon"
                                                                      size="15"
                                                                      style="margin-right: 10px;"
                                                                      />
                                                                      Ver datos</b-dropdown-item>
                                                                  </b-dropdown>
                                              </td>
                      
                                    
                      
                                          </tr>
                                        </tbody>
                                      </table>
                      
                                    </b-col>

            </b-row>
          </b-card>

    
  <b-row v-else-if="notAllow">

<b-col  cols="12" sm="12" md="12" xl="12" lg="12" align="center">

  <img style="width:100px;" src="/img/caution.svg" />

  <p style="margin-top:10px; font-size:14px; ">Tu rol no te permite utilizar la siguiente funcionalidad.</p>


  </b-col>

</b-row>

  <b-card v-else-if="empty">

    <b-row >
    
      <b-col  cols="12" sm="12" md="12" xl="12" lg="12">
    
    <h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
          <feather-icon
                    icon="ListIcon"
                    size="21"
                    class="color_icon"
                    /> Remesas pendientes
            </h4>
    
    </b-col>              
    
      <b-col  align="center" cols="12" sm="12" md="12" xl="12" lg="12">
              <svg class="icons_colors"  viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g data-name="17. Withdraw" id="_17._Withdraw"><path d="M31 0H1A1 1 0 0 0 0 1V13a1 1 0 0 0 1 1H6V31a1 1 0 0 0 1 1H25a1 1 0 0 0 1-1V14h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zM2 2H30V4H2zM24 30H8V10H24zm6-18H26V10h1a1 1 0 0 0 0-2H5a1 1 0 0 0 0 2H6v2H2V6H30z"/><path d="M29 23a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V24a1 1 0 0 0-1-1z"/><path d="M3 16a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V17a1 1 0 0 0-1-1z"/><path d="M21 16h-.18A3 3 0 0 0 15 17a1 1 0 0 1-2 0V15a1 1 0 0 0-2 0v1a1 1 0 0 0 0 2h.18A3 3 0 0 0 17 17a1 1 0 0 1 2 0v2a1 1 0 0 0 2 0V18a1 1 0 0 0 0-2z"/><path d="M16 23a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0V24a1 1 0 0 0-1-1z"/></g></svg>                    
                                                      
    
                              <p class="errorTexto">
                            No se encontraron remesas
                          </p>
    
                  </b-col>
    
      <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="    text-align: center;">
    
                                <b-button   variant="primary"  @click="refresh()" > 
                                              <feather-icon
                                              icon="RefreshCcwIcon"
                                              size="16"
                                              
                                              style="margin-right:5px; color:#fff"
                                              /> Recargar listado </b-button>
    
    
      </b-col>
      </b-row>
    </b-card>
        
    </template>
        
        <script>
        import {
          BButton, BRow,VBPopover, BCol,BDropdown,BDropdownItem,BSkeleton, BCard,BSkeletonTable, BAvatar,BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup, 
        } from 'bootstrap-vue'
        import "jquery/dist/jquery.min.js";
        import $ from "jquery";
        import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css'
        import 'datatables.net-responsive-dt/js/responsive.dataTables.min.js'
        var FileSaver = require('file-saver');

        export default {
          components: {
            BButton,
            BSkeleton,
            BInputGroup,
            BFormInput,
            BInputGroupPrepend,
            BInputGroupAppend,
            BFormGroup,
            BSkeletonTable,
            BRow,
            BCol,
            BCard,
            BDropdown,BDropdownItem,
            BAvatar
          },
          directives: {
    'b-popover': VBPopover,
  },
          props: ['userId','tokenAuth', 'idAdmin'],
          data() {
        
            const mq = window.matchMedia( "(min-width: 990px)" );
        let columnas=6;
    
        if (!mq.matches) {
    
          columnas =3;
          }


        
            return {
              code200: false,
              loading: true,
               empty:false,
               notAllow:false,
               isDisabled:false,
               isActive:false,
               columnas:columnas,
               transactions:[],
               dniContact:"",
               phoneNumberContact:"",
               emailContact:"",
               nameBankContact:"",
               numberAccountContact:"",
               typeAccountContact:"",
               date:"",
               displayNameWhoReceive:"",
               contactWhoSend:"",
               dniWhoSend:"",
               displayNameWhoSend:"",
               currency:"",
               mountRemittance:"",
               mount:"",
               transactionsId:"",
               countryContact:"",
               mountReal:"",
               rate:""

   
       


        
        
            }
        
        
          },
          computed: {
        
          },
          watch: {
        
          },
          mounted() {

   


    
            this.loadTable();


        
        
          },
          methods: {
        
            refresh() {
              
              this.$eventBus.$emit('reiniciarCountRemesasAdmin')
              this.$eventBus.$emit('reiniciarRemesasAdmin')
            },
            aprobarRechazar(idTransaction, userId, tipo, displayNameWhoReceive, displayNameWhoSend, currency, mountRemittance){


              let html="";
              let title="";
              let textButton="";

              html='<div style="margin-top:10px" class="table-responsive"><table class="table table-bordered" style="text-align: center;"><tbody><tr><td class="background_table">Quién recibe:</td><td>'+displayNameWhoReceive+'</td></tr><tr><td class="background_table">Quién envia:</td><td>'+displayNameWhoSend+'</td></tr><tr> <td class="background_table">Monto:</td><td>'+mountRemittance+" "+currency+'</td></tr></tbody></table>'
                    
              if(tipo === "Aprobar"){
    
                title='¿Estas seguro que deseas aprobar esta solicitud?';
                 textButton="Aprobar";
                
              }else{
    
                title='¿Estas seguro que deseas rechazar esta solicitud?';
                 textButton="Rechazar";
    
              }
    
                this.$swal({
                title: title,
                html: html,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: textButton,
                cancelButtonText: 'Cancelar',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-secondary ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                  const data_json = { userId: this.userId, userIdUser:userId,idAdmin:this.idAdmin,  tipo:tipo, idTransaction:idTransaction  }
                  const data_string = JSON.stringify(data_json)
    
               
    
                  const dataEncripted = this.$encryptAdmin.encrypt(data_string)
    
                  return this.$https.post('/administracion/remesas/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => response.data).catch(error => {
                    this.$swal.showValidationMessage(
                      error,
                    )
                  })
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
              }).then(result => {
    
                this.$swal.close()
    
                if(result.isConfirmed){
    
    
                  if (result.value.code == 200) {

                    this.refresh();
                       
                            this.$toast.success(result.value.message, {
                              position: 'top-right',
                              timeout: 3010,
                              closeOnClick: true,
                              pauseOnFocusLoss: false,
                              pauseOnHover: true,
                              draggable: true,
                              draggablePercent: 0.4,
                              showCloseButtonOnHover: false,
                              hideProgressBar: true,
                              closeButton: 'button',
                              icon: true,
                              rtl: false,
                            })
    
    
            
                          } else {
                            this.$toast.error(result.value.message, {
                              position: 'top-right',
                              timeout: 3010,
                              closeOnClick: true,
                              pauseOnFocusLoss: false,
                              pauseOnHover: true,
                              draggable: true,
                              draggablePercent: 0.4,
                              showCloseButtonOnHover: false,
                              hideProgressBar: true,
                              closeButton: 'button',
                              icon: true,
                              rtl: false,
                            })
    
                            if (result.data.code == 401) {
                              
                              localStorage.removeItem('userData')
 
    
                              // Redirect to login page
                              this.$router.push({ name: 'auth-login' })
                            }else{
                              if (result.data.code == 402) {
                                this.refresh();

                              }
                            }
                          }
    
                }
                
    
    
    
          
              })
    
           
    
    
            },verDatos(countryContact,dniContact,phoneNumberContact,emailContact,nameBankContact,numberAccountContact,typeAccountContact,displayNameWhoReceive,contactWhoSend,dniWhoSend,displayNameWhoSend,currency,mountRemittance,dateFormated, transactionsId, mount, mountReal, rate){
    
                this.dniContact= dniContact;
                this.countryContact= countryContact;
                  this.phoneNumberContact= phoneNumberContact;
                  this.emailContact= emailContact;
                  

                  this.nameBankContact= nameBankContact
                  this.numberAccountContact= numberAccountContact;
                  this.typeAccountContact= typeAccountContact;
                  this.date= dateFormated;
                  this.displayNameWhoReceive= displayNameWhoReceive;
                  this.contactWhoSend= contactWhoSend;

                  this.dniWhoSend= dniWhoSend;

                  this.displayNameWhoSend= displayNameWhoSend;
                  this.currency= currency;
                  this.mountRemittance= mountRemittance;
                  this.mount= mount;
                  this.transactionsId= transactionsId;

                  this.mountReal= mountReal;
                  this.rate= rate;

                  this.$refs.modalDatos.show();
            },
            download(){

            this.$swal({

              html:
              '<img style="margin-left:10px; width: 30px" src="/img/loading.svg" alt="Loading" /> Generando archivo',
              showCloseButton: false,
              showCancelButton: false,
              focusConfirm: false,
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              position: 'center',
              })

            const data_json = { userId: this.userId, idAdmin: this.idAdmin }
            const data_string = JSON.stringify(data_json)


            const dataEncripted = this.$encryptAdmin.encrypt(data_string);

              this.$https.post('/administracion/generateTxt/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
                this.$swal.close()


                  
                if (response.data.code == 200) {
                  let referencia= response.data["referencia"];
                  let contenido= response.data["contenido"];

                  let agente=response.data["agente"]; 

                  let nameFile= "TR_"+agente+"_"+referencia+".txt";
              
                  var blob = new Blob([contenido], {type: "text/plain;charset=utf-8"});
                  FileSaver.saveAs(blob, nameFile);

                  this.$toast.success(response.data.message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                      })
                  
                } else {


                  if (response.data.code == 401) {

                      this.$toast.error(response.data.message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                      })

                      
                      localStorage.removeItem('userData')



                      
                      

                      // Redirect to login page
                      this.$router.push({ name: 'auth-login' })
                      } else {

                        
                        this.$toast.error(response.data.message, {
                                  position: 'top-right',
                                  timeout: 3010,
                                  closeOnClick: true,
                                  pauseOnFocusLoss: false,
                                  pauseOnHover: true,
                                  draggable: true,
                                  draggablePercent: 0.4,
                                  showCloseButtonOnHover: false,
                                  hideProgressBar: true,
                                  closeButton: 'button',
                                  icon: true,
                                  rtl: false,
                                })

                        if (response.data.code == 403) {

                          this.$router.push({ name: 'dashboard-analytics' })

                          }



                      } 
                    
                  
                }
              }).catch(error => {
                  this.loadTable();
              })


            },
            loadTable(){
              const data_json = { userId: this.userId, idAdmin: this.idAdmin }
              const data_string = JSON.stringify(data_json)
    
        
              const dataEncripted = this.$encryptAdmin.encrypt(data_string);
    
                this.$https.post('/administracion/listRemesas/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {

                  this.loading=false;
            
    
                    
                  if (response.data.code == 200) {
                    this.transactions= response.data["transactions"];
                
                                
                        setTimeout(() => {
                          $("#datatableRemesas").DataTable({
                            responsive: true,
                            "ordering": false,
                            columnDefs: [
                                                    { responsivePriority: 1, targets: 0 },
                                                    { responsivePriority: 2, targets: -1 }
                                                ],
                            lengthMenu: [
                              [15,25, 35, 50, -1],
                              [15,25, 35, 50, "All"],
                            ],
                            pageLength: 15, 
                               "language": {
                                                  "search": "Buscar:",
                                                  "sInfo":      "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                                                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                                  "searchPlaceholder": "Ingrese algún dato",
                                                  "zeroRecords": "Lo sentimos, no se encontraron resultados",
                                                  "processing": "Buscando. Espere por favor...",
                                                  "sLengthMenu":     "Mostrar _MENU_ registros",
                                                  "oPaginate": {
                                                          "sFirst":    "Primero",
                                                          "sLast":     "Último",
                                                          "sNext":     "Siguiente",
                                                          "sPrevious": "Anterior"
                                                      },
    
                                                  }
                          });
                        });
    
                            this.code200=true;
    
                  
                    
    
    
                    
                  } else {
                    
                        
    
    
                    if (response.data.code == 401) {
    
                        this.$toast.error(response.data.message, {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })
    
                        
                        localStorage.removeItem('userData')


    
                        
    
    
    
    
                        
                        
    
                        // Redirect to login page
                        this.$router.push({ name: 'auth-login' })
                        } else {
    
                          if (response.data.code == 403) {
    
                            this.$router.push({ name: 'dashboard-analytics' })
    
                            }else{

                              
                          if(response.data.code === 501){

                          this.notAllow=true;

                          }else{

                            if (response.data.code == 404) {
    
    
                              
                              this.empty=true;

                              }else{

                                this.$toast.error(response.data.message, {
                                  position: 'top-right',
                                  timeout: 3010,
                                  closeOnClick: true,
                                  pauseOnFocusLoss: false,
                                  pauseOnHover: true,
                                  draggable: true,
                                  draggablePercent: 0.4,
                                  showCloseButtonOnHover: false,
                                  hideProgressBar: true,
                                  closeButton: 'button',
                                  icon: true,
                                  rtl: false,
                                })

                                this.code200 = false

                              }


                            }
                             
    
                            }
    
                  
    
    
    
                        } 
                      
                    
                  }
                }).catch(error => {
                    this.loadTable();
                })
            
            }
        
        
          },
        }
        </script>
        
        <style lang="scss">
        
        
        </style>