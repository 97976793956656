<template>


<b-card>



        
<b-tabs
    class="row"
  content-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-md-0"
  pills
  nav-wrapper-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
  nav-class="nav-left"
 align="center"
>



  <b-tab active>

    <template #title>
      <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block">   
          <feather-icon
                icon="SearchIcon"
                size="16"
                class="color_icon"
                /> Búsqueda de usuario</span>
      <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">    
        <feather-icon
                icon="SearchIcon"
                size="16"
                class="color_icon"
                /> Búsqueda de usuario</span>
    </template>

    
    <b-row>

<modal-upload-depositos v-if="openModalRecargarBanco" :banks="banks" :key="componentKeyModalRecargaBanco" :user-id="userId"  :user-id-user="userIdUser" :token-auth="tokenAuth" :id-admin="idAdmin"  />
<modal-create-negocio v-if="openModalNegocio" :key="componentKeyModalNegocio"  :user-id="userId" :token-auth="tokenAuth"  :user-id-user="userIdUser" :id-admin="idAdmin"/>

<b-modal
ref="modalBloquear"
centered
hide-footer
size="sm"
title="Bloquear usuario"
>
<div class="d-block text-center">

  <b-form
    autocomplete="off"
    @submit.prevent="onSubmitBloquear"
  >

    <b-row>

      <b-col
        sm="12"
        md="12"
        lg="12"
        xl="12"
        style="margin-bottom: 10px;"
      >


      <p class="textLabel" style="text-align: left;margin-top: 0px;margin-bottom: 5px;">
          Ingrese el motivo del bloqueo (*):</p>

           <b-form-textarea
          v-model="motivoBloqueo"
          placeholder="Ingrese una descripción detallada del motivo del bloqueo para este usuario"
          rows="3"
          no-resize
          :readonly="isDisabledBlock"
          required
        />

      

      </b-col>

      <b-col
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >

        <p
          class="textLabel"
          style="text-align: center;margin-bottom: 10px;"
        >
          Ingrese su código PIN (*): <feather-icon
                            icon="HelpCircleIcon"
                            size="16"
                              v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                            title="Código PIN de seguridad"
                            style="margin-right: 5px;cursor:pointer;"
                            /> </p>

        <PincodeInput
          v-model="pinBloquear"
          placeholder="-"
          :length="6"
          :autofocus="false"
          :secure="true"
          :disabled="isDisabledBlock"
          required
        />

      </b-col>

      <b-col
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >

        <b-button
          id="btn_bloquear"
          ref="btn_bloquear"
          type="submit"
          variant="primary"
          :disabled="isDisabledBlock"
        >

          Bloquear usuario

        </b-button>

      </b-col>

    </b-row>
  </b-form>

</div>

</b-modal>


<b-modal
ref="modalDesbloquear"
centered
hide-footer
size="lg"
title="Desbloquear usuario"
>
<div class="d-block text-center">
  <b-row>
    <b-col
        sm="12"
        md="12"
        lg="5"
        xl="5"
        style="margin-bottom: 10px;"
      >
  <b-form
    autocomplete="off"
    @submit.prevent="onSubmitDesbloquear"
  >
  <b-card>

    <b-row>




      <b-col
        sm="12"
        md="12"
        lg="12"
        xl="12"
        style="margin-bottom: 10px;"
      >


      <p class="textLabel" style="text-align: left;margin-top: 0px;margin-bottom: 5px;">
          Ingrese el motivo del desbloqueo (*):</p>

           <b-form-textarea
          v-model="motivoDesbloqueo"
          placeholder="Ingrese una descripción detallada del motivo para desbloquear a este usuario"
          rows="3"
          no-resize
          :readonly="isDisabledBlock"
          required
        />

      

      </b-col>


      <b-col
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >

        <p
          class="textLabel"
          style="text-align: center;margin-bottom: 10px;"
        >
          Ingrese su código PIN (*): <feather-icon
                            icon="HelpCircleIcon"
                            size="16"
                              v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                            title="Código PIN de seguridad"
                            style="margin-right: 5px;cursor:pointer;"
                            /> </p>

        <PincodeInput
          v-model="pinDesbloquear"
          placeholder="-"
          :length="6"
          :autofocus="false"
          :secure="true"
          :disabled="isDisabledBlock"
          required
        />

      </b-col>

      <b-col
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >

        <b-button
          id="btn_desbloquear"
          ref="btn_desbloquear"
          type="submit"
          variant="primary"
          :disabled="isDisabledBlock"
          block
        >

          Desbloquear usuario

        </b-button>

      </b-col>

    </b-row>

    </b-card>
  </b-form>

  </b-col>



  <b-col cols="12" sm="12" md="12" lg="7" xl="7" style="padding:0px;">
  <b-card>

  <b-tabs
      class="row"
    content-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-md-0"
    pills
    nav-wrapper-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
    nav-class="nav-left"
   align="center"
  >



    <b-tab active>

      <template #title>
        <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block">   
            <feather-icon
                  icon="UserXIcon"
                  size="16"
                  class="color_icon"
                  />Bloqueos</span>
        <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">    
          <feather-icon
                  icon="UserXIcon"
                  size="16"
                  class="color_icon"
                  /> Bloqueos</span>
      </template>
    
      <listado-motivos-bloqueos
        :key="componentKeyListadoMotivosBloqueos"
        :user-id="userId"
        :user-id-user="userIdUser"
        :id-admin="idAdmin"
        :token-auth="tokenAuth"
      />


    </b-tab>

    <b-tab @click="listMotivosDesbloqueos">

      <template #title>


        <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block">
           <feather-icon
                  icon="UserCheckIcon"
                  size="16"
                  class="color_icon"
                  />
                  Desbloqueos</span>
        <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">
           <feather-icon
                  icon="UserCheckIcon"
                  size="16"
                  class="color_icon"
                  />
                  Desbloqueos</span>
      </template>

      <listado-motivos-desbloqueos
        v-if="ifMotivosDesbloqueos"
        :key="componentKeyListadoMotivosDesbloqueos"
        :user-id="userId"
        :user-id-user="userIdUser"
        :id-admin="idAdmin"
        :token-auth="tokenAuth"
      />

    </b-tab>

   

  </b-tabs>


  </b-card>




  </b-col>
</b-row>

</div>

</b-modal>


<b-modal
ref="modalTransacciones"
centered
hide-footer
title="Transacciones entrantes"
size="lg"
@hidden="closeModalListadoMovientosEntrantes"
>
<div class="d-block text-center">


        <listado-movimientos v-if="showMovientosEntrantes" :user-id="userId" :user-id-user="userIdUser" :token-auth="tokenAuth" :id-admin="idAdmin" />

    


 

</div>

</b-modal>


<b-modal
ref="modalTransaccionesSalientes"
centered
hide-footer
title="Transacciones salientes"
size="lg"
@hidden="closeModalListadoMovientosSalientes"
>
<div class="d-block text-center">


        <listado-movimientos-salientes v-if="showMovientosSalientes" :user-id="userId" :user-id-user="userIdUser" :token-auth="tokenAuth" :id-admin="idAdmin" />

    


 

</div>

</b-modal>


<b-modal
ref="modalRetiros"
centered
hide-footer
title="Solicitudes de retiros de dinero"
size="lg"
>
<div class="d-block text-center">


        <listado-retiros  :user-id="userId" :user-id-user="userIdUser" :token-auth="tokenAuth" :id-admin="idAdmin" />

    


 

</div>

</b-modal>

<b-col  cols="12" sm="12" md="12" xl="12" lg="12">


      
      </b-col>        

      <b-col  cols="12" sm="12" md="12" xl="2" lg="2"> </b-col>
      
      
      <b-col  cols="12" sm="12" md="12" xl="8" lg="8">





          <b-form
          autocomplete="off"
          @submit.prevent="onSubmit"
          
        >

        <b-row>

          <b-col  cols="12" sm="12" md="12" xl="8" lg="8" style="margin-bottom:15px">

    
            
              <b-form-input
              v-model="search"
              placeholder="Ingrese el userID, celular, dni o email del usuario"
              required
              :readonly="isDisabled"
              type="text"
              />


          </b-col>

          <b-col  cols="12" sm="12" md="12" xl="4" lg="4" >

              <b-button
                  id="btn_search"
                  ref="btn_search"
                  type="submit"
                  variant="primary"
                  :disabled="isDisabled"
                  block
              >

               Buscar usuario

        </b-button>


          </b-col>

        </b-row>

        
       

        </b-form>





      </b-col>

      <b-col  cols="12" sm="12" md="12" xl="2" lg="2"> 
        
      </b-col> 


      <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="margin-top:15px">




          <div class="table-responsive ">
                   <table class="table table-bordered" style="text-align: center;">

                    <tbody>
                      <tr>
             
                        <th class="background_table2">Nombres y apellidos</th>
                        <th class="background_table2">¿Verificado?</th>
                        <th class="background_table2">Cédula/DNI</th>
                        <th class="background_table2">Contacto</th>
                        <th class="background_table2">Bloqueado</th>
                        <th class="background_table2">Acción</th>

                      
                      </tr>

                      <tr v-if="isSearch">
                          <td>---</td>
                          <td>---</td>
                          <td>---</td>
                          <td>---</td>
                          <td>---</td>
                          <td rowspan="5">---</td>
                      
                      </tr>

                        <tr v-else>
                          <td class="sin_fontweigth">{{displayName}}</td>
                          <td class="sin_fontweigth" v-if="isProfileVerificated"><img src="/img/verificadoFinal.svg"/></td>
                          <td class="sin_fontweigth" v-else><img src="/img/no_verificadoFinal.svg"/></td>
                          <td class="sin_fontweigth">{{dni}}</td>
                          <td class="sin_fontweigth">{{contacto}}</td>
                          <td class="sin_fontweigth" v-if="isBan">
                            Si
                            
                            <b-button
                            style="margin-top:10px"
                                      variant="danger"
                                      block
                                      size="sm"
                                      @click="banear('desbloquear')"
                                  >

                                  Desbloquear

                            </b-button>

                          </td>
                          <td class="sin_fontweigth" v-else>
                            No

                            <b-button
                            style="margin-top:10px"
                                      variant="danger"
                                      block
                                      size="sm"
                                      @click="banear('bloquear')"
                                  >

                                  Bloquear

                            </b-button>
                          
                          </td>
                          <td rowspan="5" class="sin_fontweigth">
                            <b-dropdown  dropleft variant="light" size="sm"  no-caret>
                                        <template  #button-content>

                                        <feather-icon
                                          icon="MoreVerticalIcon"
                                          size="25"
                                          class="MoreVerticalIcon"
                                          />
                                          


                                        </template>
                                        <b-dropdown-item v-if="!isProfileVerificated" @click="verificar">
                                          <feather-icon
                                          icon="CheckCircleIcon"
                                          size="15"
                                          style="margin-right: 10px;"
                                          />

                                          Verificar usuario
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="verDatosTransacciones()">
                                          <feather-icon
                                          icon="EyeIcon"
                                          size="15"
                                          style="margin-right: 10px;"
                                          />
                                        
                                          Ver transacciones entrantes
                                        </b-dropdown-item>

                                        <b-dropdown-item @click="verDatosTransaccionesSalientes()">
                                          <feather-icon
                                          icon="EyeIcon"
                                          size="15"
                                          style="margin-right: 10px;"
                                          />
                                        
                                          Ver transacciones salientes
                                        </b-dropdown-item>

                                        <b-dropdown-item @click="verRetiros()">
                                          <feather-icon
                                          icon="EyeIcon"
                                          size="15"
                                          style="margin-right: 10px;"
                                          />
                                        
                                          Ver solicitudes de retiros de dinero
                                        </b-dropdown-item>

                                        
                                        <b-dropdown-item @click="banco()">
                                          <feather-icon
                                          icon="UploadIcon"
                                          size="15"
                                          style="margin-right: 10px;"
                                          />
                                        
                                          Subir transferencia
                                        </b-dropdown-item>

                                        <!--

                                        <b-dropdown-item @click="newNegocio()" v-if="showRegisterNegocio">
                                          <feather-icon
                                          icon="HomeIcon"
                                          size="15"
                                          style="margin-right: 10px;"
                                          
                                          />
                                        
                                          Registrar negocio
                                        </b-dropdown-item>

                                        -->

                                      </b-dropdown>
                          </td>
                      </tr>

                      <tr>
             
                        <th class="background_table2">EQCoins</th>
                        <th class="background_table2">Alias</th>
                        <th class="background_table2">Balance</th>
                        <th class="background_table2">Fecha de registro</th>
                        <th class="background_table2">Ciudad</th>
                      
                      
                      </tr>

                      <tr v-if="isSearch">
                          <td>---</td>
                          <td>---</td>
                          <td>---</td>
                          <td>---</td>
                          <td>---</td>
                          
                      
                      </tr>

                      <tr v-else>
                          <td class="sin_fontweigth">{{EQPoints}}</td>
                          <td class="sin_fontweigth" v-if="alias == ''">---</td>
                          <td class="sin_fontweigth" v-else>{{alias}}</td>
                          <td class="sin_fontweigth">${{balance}}</td>
                          <td class="sin_fontweigth">{{dateRegister}}</td>
                          <td class="sin_fontweigth" v-if="city == ''">---</td>
                          <td class="sin_fontweigth" v-else>{{city}}</td>
                        
                      </tr>


                      <tr>
             
                        <th class="background_table2">Fecha nacimiento</th>
                        <th class="background_table2">Promotor</th>
                        <th class="background_table2">País</th>
                        <th class="background_table2">Plan negocios EQCoop</th>
                        <th class="background_table2">Nacionalidad</th>
                      
                      
                      </tr>

                      <tr v-if="isSearch">
                          <td>---</td>
                          <td>---</td>
                          <td>---</td>
                          <td>---</td>
                          <td>---</td>
                          
                      
                      </tr>

                      <tr v-else>
                          <td class="sin_fontweigth"><span v-if="nacimiento == ''">---</span><span>{{nacimiento}}</span></td>
                          <td class="sin_fontweigth"  >
                            
                            <span v-if="userPromoter == 'No'">No tiene</span>
                            <div v-else-if="buscarPromoter">
                              <b-skeleton  width="100%"  height="10px" style="margin-bottom:5px"></b-skeleton>
                            <b-skeleton  width="100%"  height="10px" style="margin-bottom:5px"></b-skeleton>
                            
                            </div>
                           <p v-else style="margin-bottom:0px"><b>Nombre:</b> {{namePromotor}} <br> <b>Contacto:</b> {{contactoPromotor}}</p> 
                                        
                                        </td>

                          <td class="sin_fontweigth"><span v-if="country == ''">---</span><span>{{country}}</span></td>
                       
                          <td class="sin_fontweigth"  >
                            <change-plan v-if="showDivChangePlan" :key="componentKeyChangePlan" :user-promoter="userPromoter" :user-id="userId" :token-auth="tokenAuth"  :user-id-user="userIdUser" :id-admin="idAdmin"/>


                            
                         
                      
                                        
                                        </td>

                          <td class="sin_fontweigth"><span v-if="nacionalidad == ''">---</span><span>{{nacionalidad}}</span></td>
                        
                      </tr>


               
                    
                    </tbody>
                  </table>
                  </div>

                  <b-row>

                  </b-row>


      </b-col>



      <b-col cols="12" sm="12" md="12" lg="4" xl="4"  style="margin-top:10px">
   
             

   <div class="table-responsive" style="margin-bottom:10px">
       <table class="table table-bordered" style="text-align: center;">

       
         <tbody>
           <tr>
               <td class="background_table2" colspan="3">Fondos de ahorros</td>
            </tr>
           
            <tr v-if="isSearch">
 
            
            
                 <p class="errorTexto" style="font-size:12px">
                          Busque a un usuario para cargar estos datos
                </p>
           
            </tr>

            <tr v-else-if="buscarFondos">
 
              <td class="background_table2">
                <b-skeleton  width="100%"  height="10px" style="margin-bottom:5px"></b-skeleton>
              </td>
               <td class="background_td_table">
                <b-skeleton  width="100%"  height="10px" style="margin-bottom:5px"></b-skeleton>
               </td>

               <td class="background_td_table">
                <b-skeleton  width="100%"  height="10px" style="margin-bottom:5px"></b-skeleton>
               </td>

              </tr>

              <tr v-else-if="code200Fondos"  v-for="fondo in fondos" :key="fondo.id">

                <td class="background_table2">
                {{fondo.name}}
              </td>
               <td class="background_td_table">
                ${{fondo.mount}} USD
               </td>

               <td class="background_td_table">
                {{fondo.dateFormated}} 
               </td>
                      

              </tr>

              <tr v-else>

                <svg style="margin-top:10px" class="icons_colors" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 480.61 480.61" ><g><path d="M469.012 20.343l-2.395-6.339-6.339-2.394C439.878 3.906 418.436.0 396.547.0c-48.104.0-93.33 18.733-127.346 52.749l-48.227 48.227-107.787 5.228-90.214 90.213 77.783 24.777-28.763 28.762 16.802 16.802-37.224 57.056 105.235 105.237 57.057-37.224 16.802 16.802 28.763-28.763 24.775 77.783 90.216-90.214 5.227-107.786 48.227-48.227C477.832 161.462 493.98 86.459 469.012 20.343zM79.029 182.788l47.182-47.181 63.197-3.065-64.773 64.773L79.029 182.788zM160.805 390.623l-70.806-70.806 20.473-31.381 81.713 81.714L160.805 390.623zM345.015 354.412l-47.181 47.181-14.527-45.606 64.773-64.773L345.015 354.412zM230.665 366.202 114.419 249.956 290.414 73.961C318.764 45.612 356.455 30 396.547 30c15.957.0 31.625 2.487 46.671 7.401 17.451 53.48 3.463 112.785-36.558 152.807L230.665 366.202z"/><path d="M364.951 70.67c-12.02.0-23.32 4.681-31.819 13.18-17.546 17.545-17.546 46.094.0 63.64 8.499 8.499 19.8 13.18 31.819 13.18s23.32-4.681 31.819-13.18c17.546-17.545 17.546-46.094.0-63.64C388.272 75.351 376.971 70.67 364.951 70.67zM375.558 126.277c-2.833 2.833-6.6 4.394-10.606 4.394s-7.773-1.561-10.606-4.393c-5.849-5.849-5.849-15.365.0-21.214 2.833-2.833 6.6-4.393 10.606-4.393s7.773 1.56 10.606 4.393C381.406 110.912 381.406 120.429 375.558 126.277z"/><path d="M.013 375.612l33.999-34 21.213 21.213-33.999 33.999L.013 375.612zM105.004 480.61l-21.213-21.213 33.999-33.999 21.213 21.213L105.004 480.61zM25.031 432.878l50.122-50.122 21.213 21.213-50.122 50.122L25.031 432.878z"/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>

                <p class="errorTexto" style="font-size:12px">
                        Este usuario no posee ningún fondo de ahorro
                </p>

                </tr>


            
       

   
         
         </tbody>

       </table>
       </div>

      
  
 
 </b-col>


 <b-col cols="12" sm="12" md="12" lg="4" xl="4"  style="margin-top:10px">
   
             

   <div class="table-responsive" style="margin-bottom:10px">
       <table class="table table-bordered" style="text-align: center;">

       
         <tbody>
           <tr>
               <td class="background_table2" colspan="2">Billeteras de criptomonedas</td>
            </tr>
           
            <tr v-if="isSearch">
 
            
            
                 <p class="errorTexto" style="font-size:12px">
                          Busque a un usuario para cargar estos datos
                </p>
           
            </tr>

            <tr v-else-if="buscarWallets">
 
              <td class="background_table2">
                <b-skeleton  width="100%"  height="10px" style="margin-bottom:5px"></b-skeleton>
              </td>
               <td class="background_td_table">
                <b-skeleton  width="100%"  height="10px" style="margin-bottom:5px"></b-skeleton>
               </td>
                      
            
             
            
              </tr>

              <tr v-else-if="code200Wallets"  v-for="wallet in wallets" :key="wallet.id">

                <td class="background_table2">
                {{wallet.name}}
              </td>


              <td class="background_td_table">

                                                                                    
              <b-input-group >

              <b-form-input
              v-model="wallet.address"


                  readonly>
                  
                  </b-form-input>

              <b-input-group-append>
                  <b-button
                  @click="onCopy(wallet.address)"
                  variant="outline-primary"
                  >

                  <feather-icon
              icon="CopyIcon"
              size="15"

              /> 
                  </b-button>
              </b-input-group-append>
              </b-input-group>


              </td>
    
                      

              </tr>

              <tr v-else>

                <svg style="margin-top:10px" class="icons_colors" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 480.61 480.61" ><g><path d="M469.012 20.343l-2.395-6.339-6.339-2.394C439.878 3.906 418.436.0 396.547.0c-48.104.0-93.33 18.733-127.346 52.749l-48.227 48.227-107.787 5.228-90.214 90.213 77.783 24.777-28.763 28.762 16.802 16.802-37.224 57.056 105.235 105.237 57.057-37.224 16.802 16.802 28.763-28.763 24.775 77.783 90.216-90.214 5.227-107.786 48.227-48.227C477.832 161.462 493.98 86.459 469.012 20.343zM79.029 182.788l47.182-47.181 63.197-3.065-64.773 64.773L79.029 182.788zM160.805 390.623l-70.806-70.806 20.473-31.381 81.713 81.714L160.805 390.623zM345.015 354.412l-47.181 47.181-14.527-45.606 64.773-64.773L345.015 354.412zM230.665 366.202 114.419 249.956 290.414 73.961C318.764 45.612 356.455 30 396.547 30c15.957.0 31.625 2.487 46.671 7.401 17.451 53.48 3.463 112.785-36.558 152.807L230.665 366.202z"/><path d="M364.951 70.67c-12.02.0-23.32 4.681-31.819 13.18-17.546 17.545-17.546 46.094.0 63.64 8.499 8.499 19.8 13.18 31.819 13.18s23.32-4.681 31.819-13.18c17.546-17.545 17.546-46.094.0-63.64C388.272 75.351 376.971 70.67 364.951 70.67zM375.558 126.277c-2.833 2.833-6.6 4.394-10.606 4.394s-7.773-1.561-10.606-4.393c-5.849-5.849-5.849-15.365.0-21.214 2.833-2.833 6.6-4.393 10.606-4.393s7.773 1.56 10.606 4.393C381.406 110.912 381.406 120.429 375.558 126.277z"/><path d="M.013 375.612l33.999-34 21.213 21.213-33.999 33.999L.013 375.612zM105.004 480.61l-21.213-21.213 33.999-33.999 21.213 21.213L105.004 480.61zM25.031 432.878l50.122-50.122 21.213 21.213-50.122 50.122L25.031 432.878z"/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>

                <p class="errorTexto" style="font-size:12px">
                        Este usuario no posee ninguna billetera de criptomoneda
                </p>

                </tr>


            
       

   
         
         </tbody>

       </table>
       </div>

      
  
 
 </b-col>


 <b-col cols="12" sm="12" md="12" lg="4" xl="4"  style="margin-top:10px">
   
             

   <div class="table-responsive" style="margin-bottom:10px">
       <table class="table table-bordered" style="text-align: center;">

       
         <tbody>
           <tr>
               <td class="background_table2" colspan="2">Suscripciones activas</td>
            </tr>

            <tr>
               <td class="background_table2">Nombre de la suscripción</td>
               <td class="background_table2">Fecha próximo de pago</td>
            </tr>

           
            <tr v-if="isSearch">
 
              <td colspan="2"><p class="errorTexto" style="font-size:12px">
                          Busque a un usuario para cargar estos datos
                </p></td>
            
                 
           
            </tr>

            <tr v-else-if="buscarSuscripciones">
 
              <td class="background_table2">
                <b-skeleton  width="100%"  height="10px" style="margin-bottom:5px"></b-skeleton>
              </td>
               <td class="background_td_table">
                <b-skeleton  width="100%"  height="10px" style="margin-bottom:5px"></b-skeleton>
               </td>
                      
            
             
            
              </tr>

              <tr v-else-if="code200Suscripciones"  v-for="suscripcion in suscripciones" :key="suscripcion.id">

                <td class="background_table2">
                {{suscripcion.name}}
              </td>
               <td class="background_td_table">
               {{suscripcion.DateProxPay}}
               </td>
                      

              </tr>

              <tr v-else>

                <td colspan="2">
                  <svg style="margin-top:10px" class="icons_colors" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 480.61 480.61" ><g><path d="M469.012 20.343l-2.395-6.339-6.339-2.394C439.878 3.906 418.436.0 396.547.0c-48.104.0-93.33 18.733-127.346 52.749l-48.227 48.227-107.787 5.228-90.214 90.213 77.783 24.777-28.763 28.762 16.802 16.802-37.224 57.056 105.235 105.237 57.057-37.224 16.802 16.802 28.763-28.763 24.775 77.783 90.216-90.214 5.227-107.786 48.227-48.227C477.832 161.462 493.98 86.459 469.012 20.343zM79.029 182.788l47.182-47.181 63.197-3.065-64.773 64.773L79.029 182.788zM160.805 390.623l-70.806-70.806 20.473-31.381 81.713 81.714L160.805 390.623zM345.015 354.412l-47.181 47.181-14.527-45.606 64.773-64.773L345.015 354.412zM230.665 366.202 114.419 249.956 290.414 73.961C318.764 45.612 356.455 30 396.547 30c15.957.0 31.625 2.487 46.671 7.401 17.451 53.48 3.463 112.785-36.558 152.807L230.665 366.202z"/><path d="M364.951 70.67c-12.02.0-23.32 4.681-31.819 13.18-17.546 17.545-17.546 46.094.0 63.64 8.499 8.499 19.8 13.18 31.819 13.18s23.32-4.681 31.819-13.18c17.546-17.545 17.546-46.094.0-63.64C388.272 75.351 376.971 70.67 364.951 70.67zM375.558 126.277c-2.833 2.833-6.6 4.394-10.606 4.394s-7.773-1.561-10.606-4.393c-5.849-5.849-5.849-15.365.0-21.214 2.833-2.833 6.6-4.393 10.606-4.393s7.773 1.56 10.606 4.393C381.406 110.912 381.406 120.429 375.558 126.277z"/><path d="M.013 375.612l33.999-34 21.213 21.213-33.999 33.999L.013 375.612zM105.004 480.61l-21.213-21.213 33.999-33.999 21.213 21.213L105.004 480.61zM25.031 432.878l50.122-50.122 21.213 21.213-50.122 50.122L25.031 432.878z"/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>

                  <p class="errorTexto" style="font-size:12px">
                          Este usuario no posee ninguna suscripción activa
                  </p>
                          
                </td>


              

                </tr>


            
       

   
         
         </tbody>

       </table>
       </div>

      
  
 
 </b-col>
     


</b-row>



  </b-tab>

  <b-tab @click="listVerificados">

    <template #title>


      <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block">
         <feather-icon
                icon="UserCheckIcon"
                size="16"
                class="color_icon"
                />
                Usuarios verificados</span>
      <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">
         <feather-icon
                icon="UserCheckIcon"
                size="16"
                class="color_icon"
                />
               Usuarios verificados</span>
    </template>


    <verificados
    v-if="ifVerificados"
              :key="componentKeyListadoVerificadosAdmin"
              :user-id="userId"
              :token-auth="tokenAuth"
              :id-admin="idAdmin"
            />

  </b-tab>

  <b-tab @click="listNoVerificados">

      <template #title>


        <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block">
          <feather-icon
                icon="UserXIcon"
                size="16"
                class="color_icon"
                />
                  Usuarios no verificados</span>
        <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">
          <feather-icon
                icon="UserXIcon"
                size="16"
                class="color_icon"
                />
                  Usuarios no verificados</span>
      </template>

      <no-verificados
    v-if="ifNoVerificados"
              :key="componentKeyListadoNoVerificadosAdmin"
              :user-id="userId"
              :token-auth="tokenAuth"
              :id-admin="idAdmin"
            />

      </b-tab>

    

</b-tabs>




</b-card>



        
    </template>
        
        <script>
        import {
          BButton, BRow,BTabs, BTab, BCol,BSkeleton,BFormTextarea,BAlert, BCard, BAvatar, BFormInput, BForm,VBPopover, BDropdown,BDropdownItem,BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormGroup, 
        } from 'bootstrap-vue'
        import PincodeInput from 'vue-pincode-input'
        import ListadoMotivosBloqueos from './ListadoMotivosBloqueos.vue'
        import ListadoMotivosDesbloqueos from './ListadoMotivosDesbloqueos.vue'
        import ModalCreateNegocio from './ModalCreateNegocio.vue'
        import ListadoMovimientos from './ListadoMovimientos.vue'
        import ListadoMovimientosSalientes from './ListadoMovimientosSalientes.vue'
        import Verificados from './Verificados.vue'
        import NoVerificados from './NoVerificados.vue'
        import ListadoRetiros from './ListadoRetiros.vue'
        import ModalUploadDepositos from './ModalUploadDepositos.vue'
        import ChangePlan from './ChangePlan.vue'
        export default {
          components: {
            BButton,
            ChangePlan,
            ListadoMovimientosSalientes,
            Verificados,
            NoVerificados,
            BTabs, BTab,ModalCreateNegocio,
            ListadoMotivosBloqueos,
            ListadoMotivosDesbloqueos,
            BDropdown,BDropdownItem,
            BSkeleton,
            BFormInput,
            BRow,
            BForm,
            BCol,
            BCard,
            PincodeInput,
            BAlert,
            BFormTextarea,
            BAvatar,
            ModalUploadDepositos,
            ListadoMovimientos,
            ListadoRetiros,BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormGroup, 
          },
          directives: {
            'b-popover': VBPopover,
          },
          props: ['userId','tokenAuth', 'idAdmin'],
          data() {
        

        
            return {
              pinBloquear:"",
              motivoBloqueo:"",
              motivoDesbloqueo:"",
              pinDesbloquear:"",
               isDisabled:false,
               isDisabledBlock:false,
               showDivChangePlan:false,
               search:"",
               isSearch:true,
               ifMotivosDesbloqueos:false,
               displayName:"",
               dni:"",
               showMovientosEntrantes:false,
               showMovientosSalientes:false,
               contacto:"",
               EQPoints:"",
               city:"",
               alias:"",
               balance:"",
               dateRegister:"",
               isProfileVerificated:false,
               isBan:false,
               buscarFondos:false,
               buscarWallets:false,
               buscarSuscripciones:false,
               userIdUser:"",
               nacimiento:"",
               userPromoter:"",
               address:"",
               country:"",
               region:"",
               nacionalidad:"",
               openModalRecargarBanco:false,
               buscarPromoter:false,
               showRegisterNegocio:false,
               code200Fondos:false,
               code200Wallets:false,
               code200Suscripciones:false,
               banks:[],
               fondos:[],
               wallets:[],
               suscripciones:[],
               namePromotor:"",
               contactoPromotor:"",
               componentKeyModalRecargaBanco:0,
               componentKeyListadoMotivosBloqueos:0,
               componentKeyListadoMotivosDesbloqueos:0,
               openModalNegocio:false,
               componentKeyModalNegocio:0,
               componentKeyChangePlan:0,
               componentKeyListadoVerificadosAdmin:0,
               componentKeyListadoNoVerificadosAdmin:0,
               ifVerificados:false,
               ifNoVerificados:false,
            }
        
        
          },
          computed: {
        
          },
          watch: {
        
          },
          mounted() {
    
            this.$eventBus.$on('reiniciarAdminBanco', () => {
      this.componentKeyModalRecargaBanco += 1
      this.openModalRecargarBanco=false;
    });

    

    this.$eventBus.$on('reiniciarListadoNoVerificadosAdmin', () => {
      this.componentKeyListadoNoVerificadosAdmin += 1
      
    });
       
    this.$eventBus.$on('reiniciarListadoVerificadosAdmin', () => {
      this.componentKeyListadoVerificadosAdmin += 1
      
    });
    
    
    this.$eventBus.$on('showButtonRegisterNegocio', () => {
      this.showRegisterNegocio= true;

    });

    this.$eventBus.$on('reiniciarAdminChangePlan', () => {
      this.componentKeyChangePlan += 1


    });
    

    this.$eventBus.$on('reiniciarModalNegocioAdmin', () => {
      this.componentKeyModalNegocio += 1
      this.openModalNegocio = false;
    });
    
  this.$eventBus.$on('reiniciarListadoMotivosBloqueos', () => {
    this.componentKeyListadoMotivosBloqueos += 1
  })


  this.$eventBus.$on('reiniciarListadoMotivosDesbloqueos', () => {
      this.componentKeyListadoMotivosDesbloqueos += 1
    });


    this.$eventBus.$on('reiniciarSuscripcionesActivasAdmin', () => {

      this.searchSuscripciones();
    });

  

        
          },
          methods: {

            listVerificados(){
      this.ifVerificados=true;
    },
    listNoVerificados(){
      this.ifNoVerificados=true;

    },

            listMotivosDesbloqueos(){
      this.ifMotivosDesbloqueos=true;

    },
            
    onCopy(copy) {
      navigator.clipboard.writeText(copy);
      this.$toast.success('Texto copiado!', {
        position: 'top-right',
        timeout: 1500,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
    },
    onError() {
      this.$toast.error('Falló en copiar el texto', {
        position: 'top-right',
        timeout: 1500,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
    },

            verificar(){

              this.$swal({
            title: "Verificación de usuario",
            text: "¿Seguro que deseas verificar a este usuario?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Verificar usuario",
            cancelButtonText: 'Cancelar',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-secondary ml-1',
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              const data_json = { userId: this.userId, userIdUser:this.userIdUser,idAdmin:this.idAdmin  }
              const data_string = JSON.stringify(data_json)

           

              const dataEncripted = this.$encryptAdmin.encrypt(data_string)

              return this.$https.post('/administracion/verificacion/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => response.data).catch(error => {
                this.$swal.showValidationMessage(
                  error,
                )
              })
            },
            allowOutsideClick: () => !this.$swal.isLoading(),
          }).then(result => {

            this.$swal.close()

            if(result.isConfirmed){


              if (result.value.code == 200) {

                this.isSearch=true;
                            this.isBan= false;

                            this.displayName= "";
                            this.isProfileVerificated= false;
                            this.dni= "";
                            this.contacto= "";
                            this.userIdUser= "";
                            this.search="";
                            this.EQPoints="";
                            this.city="";
                            this.alias="";
                            this.balance="";
                            this.dateRegister="";

                            this.nacimiento="";
                            this.userPromoter="";
                            this.address="";
                            this.country="",
                            this.region="";
                            this.nacionalidad="";
                   
                        this.$toast.success(result.value.message, {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })


        
                      } else {
                        this.$toast.error(result.value.message, {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })

                        if (result.data.code == 401) {
                          
                          localStorage.removeItem('userData')

                          

                          
                          

              

                          
                          

                          // Redirect to login page
                          this.$router.push({ name: 'auth-login' })
                        }
                      }

            }
            



      
          })


            },
            banear(type){

              this.isDisabledBlock=false;

              if(type === "bloquear"){

                this.pinBloquear="";
                this.motivoBloqueo="";

                this.$refs.modalBloquear.show()

              }else{


                this.pinDesbloquear="";
                 this.motivoDesbloqueo="";

          
                this.$refs.modalDesbloquear.show();
         


              }



            },
            banco(){

                  
            if(Number(this.banks.length) === Number("0")){

                    this.$swal({

                      html:
                      '<img style="margin-left:10px; width: 30px" src="/img/loading.svg" alt="Loading" /> Cargando bancos, espere',
                      showCloseButton: false,
                      showCancelButton: false,
                      focusConfirm: false,
                      allowEscapeKey: false,
                      allowOutsideClick: false,
                      showConfirmButton: false,
                      position: 'center',
                      })



             
                      this.$https.post('/profile/searchBancosDepositos/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {

                    


                      
                            if(response.data["code"] == 200){
                              this.$swal.close()
                              this.banks= response.data["bancos"];
                              this.openModalRecargarBanco= true;

                          
                                


                                
                              }else{

                          
                                  if (response.data.code == 401) {
                                    this.$swal.close()
                                    this.$toast.error(response.data.message, {
                                    position: 'top-right',
                                    timeout: 3010,
                                    closeOnClick: true,
                                    pauseOnFocusLoss: false,
                                    pauseOnHover: true,
                                    draggable: true,
                                    draggablePercent: 0.4,
                                    showCloseButtonOnHover: false,
                                    hideProgressBar: true,
                                    closeButton: 'button',
                                    icon: true,
                                    rtl: false,
                                  })
                              
                                          
                                          localStorage.removeItem('userData')

                                      
                                    
                                          

                                          
                                          

                                          // Redirect to login page
                                          this.$router.push({ name: 'auth-login' })
                                        }else{

                                          if(response.data.code === 406){

                                          
                                            this.$swal.close()
                                              this.$toast.error("El usuario no tiene registrado un DNI a su perfil. Debe decirle que verifique su cuenta.", {
                                              position: 'top-right',
                                              timeout: 3010,
                                              closeOnClick: true,
                                              pauseOnFocusLoss: false,
                                              pauseOnHover: true,
                                              draggable: true,
                                              draggablePercent: 0.4,
                                              showCloseButtonOnHover: false,
                                              hideProgressBar: true,
                                              closeButton: 'button',
                                              icon: true,
                                              rtl: false,
                                            })


                                          }else{
                                            this.banco();
                                          }
                                        
                                        
                                        } 

                              }
                        }).catch((error) => {

                          this.banco();
                            
                        });

                    }else{

                      this.openModalRecargarBanco= true;



                    }



            },

            onSubmitDesbloquear(event){
              event.preventDefault();

              
               
              document.getElementById('btn_desbloquear').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Desbloqueando'

                              
                const data_json = { userId: this.userId, idAdmin: this.idAdmin, userIdUser: this.userIdUser, motivo: this.motivoDesbloqueo, pin: this.pinDesbloquear }
                  const data_string = JSON.stringify(data_json)


                  const dataEncripted = this.$encryptAdmin.encrypt(data_string);
                  this.isDisabledBlock = true


                  this.$https.post('/administracion/desbloquearUser/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
                    this.isDisabledBlock = false



                      document.getElementById('btn_desbloquear').innerHTML = 'Desbloquear usuario';

                  
                      if (response.data.code == 200) {

                          this.isSearch=true;

                          this.$refs.modalDesbloquear.hide();
                          this.$toast.success(response.data.message, {
                              position: 'top-right',
                              timeout: 3010,
                              closeOnClick: true,
                              pauseOnFocusLoss: false,
                              pauseOnHover: true,
                              draggable: true,
                              draggablePercent: 0.4,
                              showCloseButtonOnHover: false,
                              hideProgressBar: true,
                              closeButton: 'button',
                              icon: true,
                              rtl: false,
                          })

                      
                      } else {
                      
                        this.$toast.error(response.data.message, {
                              position: 'top-right',
                              timeout: 3010,
                              closeOnClick: true,
                              pauseOnFocusLoss: false,
                              pauseOnHover: true,
                              draggable: true,
                              draggablePercent: 0.4,
                              showCloseButtonOnHover: false,
                              hideProgressBar: true,
                              closeButton: 'button',
                              icon: true,
                              rtl: false,
                          })

                          this.$refs.modalBloquear.hide();

                      if (response.data.code == 401) {

                      
                          
                          localStorage.removeItem('userData')



                          // Redirect to login page
                          this.$router.push({ name: 'auth-login' })
                          }
                          
                      
                      }
                  }).catch(error => {

                    this.$toast.error("Ocurrió un error inesperado", {
                              position: 'top-right',
                              timeout: 3010,
                              closeOnClick: true,
                              pauseOnFocusLoss: false,
                              pauseOnHover: true,
                              draggable: true,
                              draggablePercent: 0.4,
                              showCloseButtonOnHover: false,
                              hideProgressBar: true,
                              closeButton: 'button',
                              icon: true,
                              rtl: false,
                          })

                    
                  })


            },

            onSubmitBloquear(event) {
                  event.preventDefault();

                  
               
                  document.getElementById('btn_bloquear').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Bloqueando'

              
                  const data_json = { userId: this.userId, idAdmin: this.idAdmin, userIdUser: this.userIdUser, motivo: this.motivoBloqueo, pin: this.pinBloquear }
                    const data_string = JSON.stringify(data_json)

            
                    const dataEncripted = this.$encryptAdmin.encrypt(data_string);
                    this.isDisabledBlock = true


                    this.$https.post('/administracion/banUser/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
                      this.isDisabledBlock = false



                        document.getElementById('btn_bloquear').innerHTML = 'Bloquear usuario';

                    
                        if (response.data.code == 200) {

                             this.isSearch=true;

                             this.$refs.modalBloquear.hide();
                             this.$toast.success(response.data.message, {
                                position: 'top-right',
                                timeout: 3010,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.4,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: 'button',
                                icon: true,
                                rtl: false,
                            })

                        
                        } else {
                        
                          this.$toast.error(response.data.message, {
                                position: 'top-right',
                                timeout: 3010,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.4,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: 'button',
                                icon: true,
                                rtl: false,
                            })

                            this.$refs.modalBloquear.hide();

                        if (response.data.code == 401) {

                        
                            
                            localStorage.removeItem('userData')

   

                            // Redirect to login page
                            this.$router.push({ name: 'auth-login' })
                            }
                            
                        
                        }
                    }).catch(error => {

                      this.$toast.error("Ocurrió un error inesperado", {
                                position: 'top-right',
                                timeout: 3010,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.4,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: 'button',
                                icon: true,
                                rtl: false,
                            })

                      
                    })



            },
 
            verRetiros(){
              this.$refs.modalRetiros.show();
            },


            closeModalListadoMovientosEntrantes(){
              this.showMovientosEntrantes=false;
            },

            verDatosTransacciones(){
              this.showMovientosEntrantes=true;
              this.$refs.modalTransacciones.show();
            },

            closeModalListadoMovientosSalientes(){
              this.showMovientosSalientes=false;
            },

            verDatosTransaccionesSalientes(){
              this.showMovientosSalientes=true;
              this.$refs.modalTransaccionesSalientes.show();
            },

            onSubmit(event){
                event.preventDefault();

                this.isDisabled= true;


              

                const data_json = { userId: this.userId, idAdmin: this.idAdmin, search: this.search }
                    const data_string = JSON.stringify(data_json)

                    document.getElementById('btn_search').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Buscando'

                    const dataEncripted = this.$encryptAdmin.encrypt(data_string);

                    this.$https.post('/administracion/searchUser/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {

                        this.isDisabled=false;
                        this.search="";


                        document.getElementById('btn_search').innerHTML = 'Buscar usuario';

                   
                        this.isSearch=true;

                     
                        
                        if (response.data.code == 200) {
                            this.isSearch=false;
                            this.buscarPromoter= true;
               
                            this.buscarFondos=true;
                            this.buscarWallets=true;
                            this.buscarSuscripciones=true;
                            this.contactoPromotor="";
                            this.namePromotor= "";
                       
                            this.isBan= response.data["isBan"];
                            this.displayName= response.data["datos"]["displayName"];
                            this.isProfileVerificated= response.data["datos"]["isProfileVerificated"];
                            this.dni= response.data["datos"]["dni"];
                            this.contacto= response.data["datos"]["contacto"];
                            this.userIdUser= response.data["datos"]["userId"];
                            this.EQPoints=response.data["datos"]["EQPoints"];
                            this.city=response.data["datos"]["city"];
                            this.alias=response.data["datos"]["alias"];
                            this.balance=response.data["datos"]["balance"];
                            this.dateRegister=response.data["datos"]["dateRegister"];

                            this.nacimiento=response.data["datos"]["nacimiento"];
                            this.userPromoter=response.data["datos"]["userPromoter"];
                            this.address=response.data["datos"]["address"];
                            this.country=response.data["datos"]["country"];
                            this.region=response.data["datos"]["region"];
                            this.nacionalidad=response.data["datos"]["nacionalidad"];

                        


                        
                            if(this.userPromoter != "No"){
                              this.searchPromoter();
                            }

                            this.searchPlan();

                           
                            this.searchWallets();
                            this.searchFondos();
                            this.searchSuscripciones();
                    
                     

                        
                        } else {
                        
                            


                        if (response.data.code == 401) {

                            this.$toast.error(response.data.message, {
                                position: 'top-right',
                                timeout: 3010,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.4,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: 'button',
                                icon: true,
                                rtl: false,
                            })

                            
                            localStorage.removeItem('userData')

                           

                            




                            
                            

                            // Redirect to login page
                            this.$router.push({ name: 'auth-login' })
                            } else {

                                if (response.data.code == 403) {

                                this.$router.push({ name: 'dashboard-analytics' })

                                }else{

                                  this.$toast.error(response.data.message, {
                                        position: 'top-right',
                                        timeout: 3010,
                                        closeOnClick: true,
                                        pauseOnFocusLoss: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        draggablePercent: 0.4,
                                        showCloseButtonOnHover: false,
                                        hideProgressBar: true,
                                        closeButton: 'button',
                                        icon: true,
                                        rtl: false,
                                        })

                                }

                        



                            } 
                            
                        
                        }
                    }).catch(error => {
                      this.$toast.error("Ocurrió un error inesperado", {
                                        position: 'top-right',
                                        timeout: 3010,
                                        closeOnClick: true,
                                        pauseOnFocusLoss: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        draggablePercent: 0.4,
                                        showCloseButtonOnHover: false,
                                        hideProgressBar: true,
                                        closeButton: 'button',
                                        icon: true,
                                        rtl: false,
                                        })
                    })

            },
            searchFondos(){

              
              const data_json = { userId: this.userId, idAdmin: this.idAdmin, userIdUser: this.userIdUser }
                    const data_string = JSON.stringify(data_json)

            
                    const dataEncripted = this.$encryptAdmin.encrypt(data_string);

                    this.$https.post('/administracion/searchFondos/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {

                    
                        if (response.data.code == 200) {

                          this.buscarFondos= false;
                          this.code200Fondos= true;
                          this.fondos= response.data["fondos"];
                    
                        
                        } else {
                        
                            


                        if (response.data.code == 401) {

                            this.$toast.error(response.data.message, {
                                position: 'top-right',
                                timeout: 3010,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.4,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: 'button',
                                icon: true,
                                rtl: false,
                            })

                            
                            localStorage.removeItem('userData')

                           

                            




                            
                            

                            // Redirect to login page
                            this.$router.push({ name: 'auth-login' })
                            } else {

                              if(response.data.code === 404){
                                this.buscarFondos= false;
                                 this.code200Fondos= false;

                              }else{
                                this.searchFondos();
                              }

                             



                            } 
                            
                        
                        }
                    }).catch(error => {
                      this.searchFondos();
                    })

            },
            
            searchWallets(){

                              
                const data_json = { userId: this.userId, idAdmin: this.idAdmin, userIdUser: this.userIdUser }
                      const data_string = JSON.stringify(data_json)


                      const dataEncripted = this.$encryptAdmin.encrypt(data_string);

                      this.$https.post('/administracion/searchWallets/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {

                       
                        this.$swal.close()
                          if (response.data.code == 200) {

                            this.buscarWallets= false;
                            this.code200Wallets= true;
                            this.wallets= response.data["wallets"];
                            
                          
                          } else {
                          
                              


                          if (response.data.code == 401) {

                              this.$toast.error(response.data.message, {
                                  position: 'top-right',
                                  timeout: 3010,
                                  closeOnClick: true,
                                  pauseOnFocusLoss: false,
                                  pauseOnHover: true,
                                  draggable: true,
                                  draggablePercent: 0.4,
                                  showCloseButtonOnHover: false,
                                  hideProgressBar: true,
                                  closeButton: 'button',
                                  icon: true,
                                  rtl: false,
                              })

                              
                              localStorage.removeItem('userData')
                
                            

                              




                              
                              

                              // Redirect to login page
                              this.$router.push({ name: 'auth-login' })
                              } else {

                                if(response.data.code === 404){
                                  this.buscarWallets= false;
                                  this.code200Wallets= false;

                                }else{
                                  this.searchWallets();
                                }

                              



                              } 
                              
                          
                          }
                      }).catch(error => {
                        this.searchWallets();
                      })

                },

                searchSuscripciones(){
                  this.buscarSuscripciones= true;
                            this.code200Suscripciones= false;
                                              
                const data_json = { userId: this.userId, idAdmin: this.idAdmin, userIdUser: this.userIdUser }
                      const data_string = JSON.stringify(data_json)


                      const dataEncripted = this.$encryptAdmin.encrypt(data_string);

                      this.$https.post('/administracion/searchSuscripciones/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {

                      
                        this.$swal.close()
                          if (response.data.code == 200) {

                            this.buscarSuscripciones= false;
                            this.code200Suscripciones= true;
                            this.suscripciones= response.data["suscripciones"];
                            
                          
                          } else {
                          
                              


                          if (response.data.code == 401) {

                              this.$toast.error(response.data.message, {
                                  position: 'top-right',
                                  timeout: 3010,
                                  closeOnClick: true,
                                  pauseOnFocusLoss: false,
                                  pauseOnHover: true,
                                  draggable: true,
                                  draggablePercent: 0.4,
                                  showCloseButtonOnHover: false,
                                  hideProgressBar: true,
                                  closeButton: 'button',
                                  icon: true,
                                  rtl: false,
                              })

                              
                              localStorage.removeItem('userData')

                            

                              




                              
                              

                              // Redirect to login page
                              this.$router.push({ name: 'auth-login' })
                              } else {

                                if(response.data.code === 404){
                                  this.buscarSuscripciones= false;
                                  this.code200Suscripciones= false;

                                }else{
                                  this.searchSuscripciones();
                                }

                              



                              } 
                              
                          
                          }
                      }).catch(error => {
                        this.searchSuscripciones();
                      })

                },

                newNegocio(){

                  

                  this.$swal({

                        html:
                        '<img style="margin-left:10px; width: 30px" src="/img/loading.svg" alt="Loading" /> Comprobando estado, espere',
                        showCloseButton: false,
                        showCancelButton: false,
                        focusConfirm: false,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        position: 'center',
                        })



                        const data_json = { userId: this.userId, idAdmin: this.idAdmin, userIdUser: this.userIdUser }
                      const data_string = JSON.stringify(data_json)


                      const dataEncripted = this.$encryptAdmin.encrypt(data_string);

                        this.$https.post('/administracion/checkBusiness/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {




                              if(response.data["code"] == 200){
                                this.$swal.close()


                                if(response.data["isFirstLocal"]){
                                  this.openModalNegocio=true;

                                }else{

                                  if(response.data["hasSubcripcion"]){
                                    this.openModalNegocio=true;


                                  }else{

                                    this.$toast.error("Este usuario ya tiene creado un negocio y tiene un plan Free asi que no se puede crear otro negocio hasta que el usuario cambie su plan.", {
                                      position: 'top-right',
                                      timeout: 3010,
                                      closeOnClick: true,
                                      pauseOnFocusLoss: false,
                                      pauseOnHover: true,
                                      draggable: true,
                                      draggablePercent: 0.4,
                                      showCloseButtonOnHover: false,
                                      hideProgressBar: true,
                                      closeButton: 'button',
                                      icon: true,
                                      rtl: false,
                                    })

                                  }

                                }


                        
                                  


                                  
                                }else{

                                   
                                  this.$swal.close()
                                     

                                  this.$toast.error(response.data.message, {
                                      position: 'top-right',
                                      timeout: 3010,
                                      closeOnClick: true,
                                      pauseOnFocusLoss: false,
                                      pauseOnHover: true,
                                      draggable: true,
                                      draggablePercent: 0.4,
                                      showCloseButtonOnHover: false,
                                      hideProgressBar: true,
                                      closeButton: 'button',
                                      icon: true,
                                      rtl: false,
                                    })

           
                            
                                    if (response.data.code == 401) {
                                   

                                            
                                            localStorage.removeItem('userData')

                                        
                                      
                                            

                                            
                                            

                                            // Redirect to login page
                                            this.$router.push({ name: 'auth-login' })
                                          }

                                }
                          }).catch((error) => {

                            this.$swal.close()
                                     

                                     this.$toast.error("Ocurrió un error: " + error, {
                                         position: 'top-right',
                                         timeout: 3010,
                                         closeOnClick: true,
                                         pauseOnFocusLoss: false,
                                         pauseOnHover: true,
                                         draggable: true,
                                         draggablePercent: 0.4,
                                         showCloseButtonOnHover: false,
                                         hideProgressBar: true,
                                         closeButton: 'button',
                                         icon: true,
                                         rtl: false,
                                       })
                              
                          });

                },
  
                searchPlan(){


                  this.showDivChangePlan=true;
                  this.componentKeyChangePlan += 1;

                  

                },
            searchPromoter(){

             
          
                const data_json = { userId: this.userId, idAdmin: this.idAdmin, userPromoter: this.userPromoter }
                    const data_string = JSON.stringify(data_json)

            
                    const dataEncripted = this.$encryptAdmin.encrypt(data_string);

                    this.$https.post('/administracion/searchPromoter/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {

                      this.$swal.close()
                        if (response.data.code == 200) {

                          this.buscarPromoter= false;
                          this.contactoPromotor=response.data["contacto"];
                          this.namePromotor= response.data["displayName"];
                    
                        
                        } else {
                        
                            


                        if (response.data.code == 401) {

                            this.$toast.error(response.data.message, {
                                position: 'top-right',
                                timeout: 3010,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.4,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: 'button',
                                icon: true,
                                rtl: false,
                            })

                            
                            localStorage.removeItem('userData')

                           

                            




                            
                            

                            // Redirect to login page
                            this.$router.push({ name: 'auth-login' })
                            } else {

                              this.searchPromoter();



                            } 
                            
                        
                        }
                    }).catch(error => {
                      this.searchPromoter();
                    })

            }
        
       
        }
    }
        </script>
        
        <style lang="scss">
        .sin_fontweigth{
          font-weight: 100;
        }
        
        </style>